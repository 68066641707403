.sizebox{
  position: absolute;
  width: 10px;
  height: 10px;
  /*background-color: rgba(255, 0, 0, 0.24);*/
  z-index: 100;
  display: none;
  cursor: move;

  &.hot{
    pointer-events: none;
  }

  &.active{
    display: block;
  }

  .sizedot:nth-of-type(1){cursor: nw-resize;}
  .sizedot:nth-of-type(2){cursor: n-resize;}
  .sizedot:nth-of-type(3){cursor: ne-resize;}
  .sizedot:nth-of-type(4){cursor: e-resize;}
  .sizedot:nth-of-type(5){cursor: se-resize;}
  .sizedot:nth-of-type(6){cursor: s-resize;}
  .sizedot:nth-of-type(7){cursor: sw-resize;}
  .sizedot:nth-of-type(8){cursor: w-resize;}

  canvas{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.selectbox{
  position: absolute;
  display: none;
  z-index: 4;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 255, 0.25);*/

  .border{
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    display: none;

    svg{
      width: 100%;
      height: 100%;
      display: none;

      .white,
      .ants{
        stroke-width: 2px;
      }
    }

    &.active{
      display: block;

      svg{
        display: block;
      }
    }

    &.filled{
        display: block;
        background-color: rgba(255, 0, 0, 0.25);
    }
  }

  svg{
    .white {
      fill: none;
      stroke: #FFF;
      stroke-width: 1px;
      vector-effect: non-scaling-stroke;
    }

    .filled{
        fill: rgba(255, 0, 0, 0.25);
    }

    .ants {
      fill: none;
      stroke: #000;
      stroke-width: 1px;
      vector-effect: non-scaling-stroke;
      stroke-dasharray: 4px;
      animation: stroke 0.2s linear infinite;
      /*shape-rendering: geometricPrecision;*/
      stroke-dashoffset: 8px;
    }

    @keyframes stroke {
      to {
        stroke-dashoffset: 0;
      }
    }

  }

  .content{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .dots{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 11;
    }

    .shape{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 10;

      svg{
        width: 100%;
      }
    }

    canvas{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 9;
    }

  }

  &.active{
    display: block;
  }

  &.capture{
    /*background-color: rgba(0, 255, 0, 0.15);*/
    pointer-events: all;
  }

}

.sizedot{
  position: absolute;
  z-index: 2;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  border: 1px solid white;
  background-color: black;

  &:hover{
    background-color: white;
  }
}

.rotatedot{
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin: -20px 0 0 -20px;
  cursor: url("../_img/cursors/rotatenw.png") 0 0, auto;
  display: none;

  &.d2{
    margin: -20px -20px 0 0;
    cursor: url("../_img/cursors/rotatene.png") 0 0, auto;
  }
  &.d3{
    margin: 0 -20px -20px 0;
    cursor: url("../_img/cursors/rotatese.png") 0 0, auto;
  }
  &.d4{
    margin: 0 0 -20px -20px;
    cursor: url("../_img/cursors/rotatesw.png") 0 0, auto;
  }

  &:hover{
    /*background-color: green;*/
  }
}

.sizebox.canrotate{
  .rotatedot{
    display: block;
  }
}