.effects{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .tabs{
    position: absolute;
    left: 5px;
    width: 220px;
    border-bottom: 1px solid black;
    height: 36px;
    overflow: hidden;

    .tab{
      position: absolute;
      width: 90px;
      text-align: center;
      line-height: 24px;
      border: 1px solid black;
      height: 24px;
      bottom: -3px;
      left: 5px;
      opacity: 0.5;
      transition: all 0.3s ease-in-out;
      user-select: none;

      &:nth-of-type(2){
        left: 100px;
      }

      &:hover,
      &.active{
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
        bottom: 0;
        opacity: 1;
      }
    }
  }
  .sliders{
    position: absolute;
    left: 10px;
    top: 40px;
    display: none;

    &.active{
      display: block;
    }
  }

  .slider{
    position: relative;
    margin: 4px 0;
    label{
      display: block;
      font-size: 12px;
      text-transform: capitalize;
    }

    input[type="range"]{
      width: 200px;
      position: relative;
      z-index: 10;
      margin-top: -2px;

      &::-webkit-slider-runnable-track {
        background: linear-gradient(90deg, rgb(0,0,0) 0%, rgb(200,200,200) 100%);
        border: 1px solid #010101;
        width: 100%;
        height: 4px;
        cursor: pointer;
      }

      &::-moz-range-track {
        background: linear-gradient(90deg, rgb(0,0,0) 0%, rgb(200,200,200) 100%);
        border: 1px solid #010101;
        width: 100%;
        height: 2px;
        cursor: pointer;
      }

      &.saturation::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #7F7F7F 0%, #F50A0A 100%);
      }
      &.saturation::-moz-range-track{
        background: linear-gradient(90deg, #7F7F7F 0%, #F50A0A 100%);
      }
      &.contrast::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #7F7F7F 0%, #000000 100%);
      }
      &.contrast::-moz-range-track{
        background: linear-gradient(90deg, #7F7F7F 0%, #000000 100%);
      }
      &.hue::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
      }
      &.hue::-moz-range-track{
        background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
      }
      &.sepia::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #313335 0%, #8A4F38 100%);
      }
      &.sepia::-moz-range-track{
        background: linear-gradient(90deg, #313335 0%, #8A4F38 100%);
      }
      &.cyan::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #0AF5F5 0%, #F50A0A 100%);
      }
      &.cyan::-moz-range-track{
        background: linear-gradient(90deg, #0AF5F5 0%, #F50A0A 100%);
      }
      &.magenta::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #F50AF5 0%, #0AF50A 100%);
      }
      &.magenta::-moz-range-track{
        background: linear-gradient(90deg, #F50AF5 0%, #0AF50A 100%);
      }
      &.yellow::-webkit-slider-runnable-track{
        background: linear-gradient(90deg, #F5F50A 0%, #0A0AF5 100%);
      }
      &.yellow::-moz-range-track{
        background: linear-gradient(90deg, #F5F50A 0%, #0A0AF5 100%);
      }
    }

    input[type="text"]{
      position: absolute;
      width: 40px;
      right: 0;
      top: 0;
      font-size: 12px;
      padding: 2px 4px;
    }

  }

  .alchemy{
    position: absolute;
    left: 10px;
    top: 40px;
    width: 200px;

    display: none;

    &.active{
      display: block;
    }

    .recipe{
      padding: 6px 6px 6px 24px;
      border-bottom: 1px solid black;
      background-image: url("../_img/flask.svg");
      background-repeat: no-repeat;
      background-position: 2px center;
      background-size: 16px 16px;
      font-size: 12px;

      &:hover{
        cursor: pointer;
        background-color: $panel-background-active;
      }
    }
  }

  .code{
    position: absolute;
    left: 10px;
    top: 250px;
    right: 10px;

    code{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 170px;
      overflow: auto;
      white-space: pre;
      font-family: monospace;
      display: block;
      border: 1px solid black;
      background-color: #1c1c1c;
      color: $menu-text-color-dim;

      &:focus{
        outline: none;
      }

      .string{color:#A1E46D;}
      .number{color:#4176BA;}
      .html {color:#E4D95F;}
      .reserved {color:#C87531;}
      .js {color:#6DE4D1;}
      .globals {color:#ab66b7;}
      .comment{color:#aaa;}
      .method {color:#F9C26B;}
      .source {color: #64b969;}
      .target {color: #64b9a9;}
    }

    .button{
      position: absolute;
      left: 0;
      top: 176px;
      user-select: none;
    }

    .params{
      position: absolute;
      background-color: $panel-background-color;
      z-index: 1;
      right: 0;
      top: -242px;
      width: 256px;
      height: 206px;

      h3{
        padding-left: 0;
      }

      .slider{
        margin-left: 20px;
        margin-right: 36px;

        label,
        input[type="text"]{
          font-size: 11px;
        }

        input[type="text"]{
          padding: 1px 2px;
        }

        input[type=range]::-webkit-slider-thumb{
          height: 12px;
          margin-top: -5px;
        }
        input[type=range]::-ms-thumb {
          height: 12px;
        }
        input[type=range]::-moz-range-thumb {
          height: 10px;
        }
      }

      &.columns{
        .slider{
          margin-left: 10px;
          margin-right: 0;
          width: 110px;
          display: inline-block;

          &.main{
            width: 230px;

            input[type="range"]{
              width: 230px;
            }
          }

          input[type="range"]{
            width: 110px;
          }

          input[type="text"]{
            width: 30px;
          }
        }
      }
    }
  }

  .previewpanel{
    position: absolute;
    left: 240px;
    top: 12px;

    .preview{
      width: 200px;
      height: 200px;
      border: 1px solid black;
      margin-bottom: 10px;
    }
  }

  .buttons{
    left: 0;
    right: 0;
    justify-content: flex-end;

    .button.left{
      position: absolute;
      left: 10px;
    }
  }
}