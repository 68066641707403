.palette{
  position: relative;
  height: 281px;
  margin-top: 1px;

  &.withactions{
    .palettepanel{
      .caption{
        &:after{
         transform: rotate(180deg);
        }
      }
    }
    .mainpanel{
      left: 201px;

      .sliders{
        padding-left: 10px;
        padding-right: 10px;

        input.hex{
          right: 10px;
          width: 80px;
        }

        .pixelcount{
          left: 75px;
        }

        .slider{
          input.slider{
            width: 110px;
            margin: 4px 0 0 8px
          }

          span.label{
            right: 43px;
            font-size: 12px;
            width: 40px;
          }
        }
      }

      .buttons{
        right: 0;
      }

      .options{
        padding-left: 10px;
      }
    }

    .actions{
      display: block;
    }
  }

  .caption.sub{
    cursor: default;
    font-size: 12px;
    line-height: 17px;
  }

  .palettepanel{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 121px;
    border-right: 1px solid rgba(0, 0, 0, 0.58);
    background-color: #292b2c;

    .caption{
      &:after{
        content: "";
        position: absolute;
        width: 16px;
        height: 18px;
        right: 2px;
        background-image: url("../_img/caret.svg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0.5;
        transition: transform 0.3s ease-in-out;
      }

      &:hover{
        cursor: pointer;
        &:after{
          opacity: 1;
        }

      }
    }

    canvas{
      display: block;
      margin: 0;
    };

    .highlight{
      position: absolute;
      width: 30px;
      height: 30px;
      margin: 21px 0 0 1px;
      border: 3px solid black;
      pointer-events: none;

      &:before{
        content: "";
        position: absolute;
        right: -1px;
        bottom: -1px;
        left: -4px;
        top: -4px;
        border: 2px solid white;
      }
    }

    .nav{
      border-top: 1px solid black;
      width: 120px;
      height: 20px;
      background-color: #313335;
      display: flex;
      color: #BBBBBB;


      .prev,.next{
        width: 40px;
        background-image: url(../_img/caret.svg);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.1;
        pointer-events: none;

        &.active{
          opacity: 0.5;
          pointer-events: all;
        }

        &:hover{
          opacity: 1;
          cursor: pointer;
        }
      }

      .prev{
        transform: rotate(180deg);
      }

      .page{
        width: 40px;
        text-align: center;
      }

    }
  }

  .actions{
    position: absolute;
    left: 121px;
    width: 80px;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.58);
    display: none;

    .caption{
      &:after{
        content: "x";
        text-align: center;
        position: absolute;
        width: 16px;
        height: 18px;
        right: 2px;
        opacity: 0.5;
        transition: transform 0.3s ease-in-out;
      }

      &:hover{
        cursor: pointer;
        &:after{
          opacity: 1;
        }
      }
    }

    .spacer{
        height: 20px;
    }

    .nav{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      border-top: 1px solid black;

      div{
        width: 100%;
        height: 20px;
        background-image: url("../_img/zoom.svg");
        background-size: 14px 14px;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.5;

        &:hover{
            cursor: pointer;
            opacity: 1;
        }

        &.zoomout{
          background-image: url("../_img/zoomout.svg");
          border-left: 1px solid black;
        }
      }
    }
  }

  .mainpanel{
    position: absolute;
    left: 121px;
    right: 0;
    top: 0;
    bottom: 0;

    .tabs{
      display: flex;
      width: 100%;

      .caption.sub{
        width: 100%;
        padding-left: 10px;
        pointer-events: none;

        &.inactive{
          background-color: $panel-background-color;
          opacity: 0.5;
          pointer-events: all;

          &:hover{
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }

    .colorpanel{
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      bottom: 0;
    }

    .rangepanel{
      position: absolute;
      left: 0;
      right: 0;
      top: 20px;
      bottom: 0;
      display: none;

      .norange{
        padding: 20px;
        opacity: 0.5;
      }

      .bottom{
        position: absolute;
        bottom: 0;
        padding: 4px;
        display: flex;

        .button.small{
          position: relative;
          width: auto;

          &.active{
            border: 1px solid $active-color;
            color: $active-color;
            box-shadow: 0 0 2px yellow;
          }
        }
      }

      .captions{
        display: flex;
        font-size: 11px;
        border-bottom: 1px solid black;
        background-color: #262829;
        color: #808080;

        div{
          padding: 4px;
          border-left: 1px solid $panel-background-color;

          &.a{
            padding: 4px 2px;
            border-left: none;
          }

          &.b{
            width: 100px;
          }
        }
      }

      .ranges{
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        bottom: 30px;
        overflow: auto;
      }

      .range{
        padding: 4px 4px 4px 8px;
        overflow: auto;
        overflow-y: hidden;
        border-bottom: 1px solid black;
        border-top: 1px solid #464545;
        display: flex;
        align-items: center;

        &.active{
            background-color: #3e4144;
        }


        .checkbox{
          display: block;
        }

        .slider{
          display: block;
          width: 60px;
          margin: 0 4px;
        }

        .speed{
          width: 40px;
          margin: 0 4px 0 0;
        }

        canvas{
          display: block;
          cursor: pointer;

          &.active{
            box-shadow: 0 0 5px 2px $active-color;
          }
        }
      }
    }

    .sliders{
      position: absolute;
      left: 0;
      top: 10px;
      right: 0;
      padding: 0 20px;

      .tabs{
        display: block;
        margin-top: 10px;
        position: relative;
        .tab{
          position: absolute;
          left: 0;
          font-size: 11px;
          height: 50px;
          width: 18px;
          text-align: center;
          top: 53px;
          background-color: $panel-background-color;
          pointer-events: none;

          &:before,
          &:after{
            content: "";
            position: absolute;
            left: 0;
            right: 1px;
            border-top: 1px solid #6A6A6A;
            transform-origin: top right;
          }

          &:before{
            top: 0;
            transform: rotate(-15deg);
          }

          &:after{
            bottom: -1px;
            transform: rotate(15deg);
          }

          span{
            display: block;
            border-left: 1px solid #6A6A6A;
            margin: 5px 0;
            width: 14px;
            padding: 2px 0 2px 4px;
            height: 41px;
          }


          &:first-child{
            top: 0;
            &:before{
              right: 0;
            }
          }


          &.inactive{
            opacity: 0.4;
            margin-left: 1px;
            transition: all 0.1s ease-in-out;
            pointer-events: all;
            background-color: transparent;

            &:hover{
              cursor: pointer;
              opacity: 1;
              margin-left: -1px;
            }
          }
        }

        .panel{
          border: 1px solid #6A6A6A;
          margin-left: 16px;
        }
      }

      .slider{
        position: relative;
        margin: 8px 0;

        span.label{
          position: absolute;
          right: 49px;
          text-align: left;
          text-transform: capitalize;
          font-size: 13px;
          margin-top: 5px;
          width: 45px;
        }

        input.slider{
          width: 148px;
          margin: 4px 0 0 12px;

          &.red::-webkit-slider-runnable-track {
            background: linear-gradient(90deg, #000000 0%, #c40202 100%);
            border: none;
          }
          &.red.hsv::-webkit-slider-runnable-track{
            background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
          }
          &.green::-webkit-slider-runnable-track {
            background: linear-gradient(90deg, #000000 0%, #00a402 100%);
            border: none;
          }
          &.green.hsv::-webkit-slider-runnable-track{
            background: linear-gradient(90deg, #000000 0%, #c40202 100%);
          }
          &.blue::-webkit-slider-runnable-track {
            background: linear-gradient(90deg, #000000 0%, #085db9 100%);
            border: none;
          }
          &.blue.hsv::-webkit-slider-runnable-track{
            background: linear-gradient(90deg, #000000 0%, #FFFFFF 100%);
          }
        }

        input.rangevalue{
          position: absolute;
          right: 4px;
          width: 40px;
        }
      }

      input.hex{
        width: 100px;
        position: absolute;
        right: 20px;
      }

      input.masked{
        position: absolute;
        opacity: 0;
        pointer-events: none;
        left: 0;
        top: 0;
        height: 30px;
      }

      .pixelcount{
        position: absolute;
        left: 86px;
        top: 1px;
        font-size: 12px;
      }
    }

    .button.small{
      position: absolute;
      width: 50px;

      &.revert{
        right: 60px;
      }

      &.apply{
        right: 8px;
      }
    }


  }



  .buttons{
    position: absolute;
    left: 0;
    bottom: 72px;
    right: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.active{
      opacity: 1;
      pointer-events: all;
    }
  }

  .button.small{
    line-height: 14px;
    font-size: 12px;
    padding: 2px 4px;
    text-align: center;

    .contextmenu{
      position: absolute;

      .item{
        padding: 2px 4px;
        background-color: $panel-background-color;
        border: 1px solid black;
        border-radius: 2px;
        font-size: 12px;
        margin: 2px 0;
        width: 100px;
        text-align: left;

        &:hover{
          cursor: pointer;
          background-color: $panel-background-active;
        }
      }
    }
  }

  .options{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 5px 10px 5px 20px;
    border-top: 1px solid black;

    .checkbox{
      padding: 3px 0;
      position: relative;
      font-size: 12px;
    }
  }
}