html {
  box-sizing: border-box;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  background-color: #2b2b2b;
  height: 100%;
  margin: 0;
  padding: 1px;
  font-family: sans-serif;
  font-size: 14px;
}

.container {
  background-color: #2b2b2b;
  height: 100%;
  position: relative;
}

.container.fuzzy {
  pointer-events: none;
  filter: blur(2px);
}

h1.error {
  text-align: center;
  color: #fff;
  width: 50%;
  margin: 50px auto;
  font-size: 24px;
  font-weight: 100;
}

h1.error a {
  color: #fff;
}

.spinner {
  border: 5px solid #ffffff4d;
  border-top-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  animation: 1s linear infinite spin;
  position: absolute;
  top: 40%;
  left: 50%;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #8c8c8c #454545;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border: 2px solid #2b2b2b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #626262;
}

::-webkit-scrollbar-corner {
  background: #000;
}

.checkbox {
  display: block;
}

.checkbox label {
  position: relative;
}

.checkbox label span {
  white-space: nowrap;
  color: #bbb;
  padding: 2px 0 2px 18px;
  line-height: 14px;
}

.checkbox label span:before {
  content: "";
  background-color: #2b2b2b;
  border: 1px solid #8a8a8a;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 1px;
  left: 0;
}

.checkbox label:hover {
  cursor: pointer;
}

.checkbox label:hover span {
  color: #d7d7d7;
}

.checkbox label:hover span:before {
  border: 1px solid #e8e8e8;
}

.checkbox input {
  opacity: 0;
  position: absolute;
}

.checkbox input:checked + span:before {
  background-color: silver;
  box-shadow: inset 0 0 0 2px #000;
}

input[type="number"], input[type="text"] {
  color: #bbb;
  background-color: #2b2b2b;
  border: 1px solid #6a6a6a;
  padding: 4px;
  display: inline-block;
}

input[type="number"]:focus, input[type="text"]:focus {
  outline: none;
}

.yesno {
  border: 1px solid #6a6a6a;
  width: 70px;
  height: 18px;
  margin: 2px 2px 0 0;
  padding-top: 1px;
  font-size: 12px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.yesno .option {
  text-align: center;
  opacity: .5;
  z-index: 2;
  width: 50px;
  position: relative;
}

.yesno .option:nth-child(2) {
  opacity: 1;
  color: #000;
}

.yesno:before {
  content: "";
  background-color: #bbb;
  width: 30px;
  transition: left .3s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
}

.yesno:hover {
  cursor: pointer;
}

.yesno:hover .option {
  opacity: 1;
}

.yesno:hover .option:nth-child(2) {
  opacity: 1 !important;
}

.yesno.selected .option:first-child {
  opacity: 1;
  color: #000;
}

.yesno.selected .option:nth-child(2) {
  opacity: .5;
  color: inherit;
}

.yesno.selected:before {
  left: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  box-sizing: border-box;
  background-color: #0000;
  height: 21px;
  margin: 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #c8c8c8;
  border: 1px solid #010101;
  width: 100%;
  height: 2px;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  -webkit-appearance: none;
  background: #c8c8c8;
  border: 1px solid #323232b3;
  width: 8px;
  height: 16px;
  margin-top: -8.6px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #d5d5d5;
}

input[type="range"]::-moz-range-track {
  cursor: pointer;
  background: #c8c8c8;
  border: 1px solid #010101;
  width: 100%;
  height: 0;
  margin-top: -2px;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #c8c8c8;
  border: 1px solid #323232b3;
  border-radius: 0;
  width: 6px;
  height: 14px;
  margin-top: -2px;
}

input[type="range"]::-ms-track {
  color: #0000;
  cursor: pointer;
  background: none;
  border-width: 8px 0;
  border-color: #0000;
  width: 100%;
  height: 2px;
}

input[type="range"]::-ms-fill-lower {
  background: #bbb;
  border: 1px solid #010101;
}

input[type="range"]::-ms-fill-upper {
  background: #c8c8c8;
  border: 1px solid #010101;
}

input[type="range"]::-ms-thumb {
  cursor: pointer;
  background: #c8c8c8;
  border: 1px solid #323232b3;
  width: 8px;
  height: 16px;
  margin-top: 0;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #c8c8c8;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #d5d5d5;
}

.menu {
  color: #bbb;
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  background-color: #313335;
  border: 1px solid #000;
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
}

.menu .hamburger {
  display: none;
}

.menu a.main {
  padding: 0 10px;
  font-size: 13px;
  line-height: 23px;
  display: inline-block;
  position: relative;
}

.menu a.main .sub {
  color: #bbb;
  background-color: #313335;
  border: 1px solid #000;
  margin-top: 0;
  display: none;
  position: absolute;
  left: 0;
}

.menu a.main .sub a {
  white-space: nowrap;
  padding: 0 24px 0 10px;
  font-size: 13px;
  line-height: 23px;
  display: block;
  position: relative;
}

.menu a.main .sub a.wide {
  padding: 0 70px 0 10px;
}

.menu a.main .sub a.wide.ultra {
  padding: 0 90px 0 10px;
}

.menu a.main .sub a.caret:after {
  content: "";
  opacity: .5;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 23px;
  position: absolute;
  right: 2px;
}

.menu a.main .sub a.checked:before {
  content: "";
  background-image: url("check.e5d0aad0.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 4px;
}

.menu a.main .sub a:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.menu a.main .sub a:hover .subsub {
  display: block;
}

.menu a.main .sub a .shortkey {
  color: #8f8f8f;
  font-size: 11px;
  position: absolute;
  top: 1px;
  right: 6px;
}

.menu a.main .sub.checkable a {
  padding: 0 24px 0 20px;
}

.menu a.main .sub .subsub {
  z-index: 100;
  background-color: #313335;
  border: 1px solid #000;
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
}

.menu a.main:hover, .menu a.main.active {
  cursor: pointer;
  background-color: #3c3f41;
}

.menu a.main.active .sub {
  display: block;
}

body.presentation .menu {
  display: none;
}

.contextmenu {
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  color: #bbb;
  background-color: #313335;
  border: 1px solid #000;
  display: none;
  position: absolute;
  box-shadow: 1px 1px 3px #00000080;
}

.contextmenu.active {
  opacity: 1;
  pointer-events: all;
  display: block;
}

.contextmenu .contextmenuitem {
  white-space: nowrap;
  padding: 0 24px 0 10px;
  font-size: 13px;
  line-height: 23px;
  display: block;
  position: relative;
}

.contextmenu .contextmenuitem:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.toolbar {
  -webkit-user-select: none;
  user-select: none;
  width: 64px;
  position: absolute;
  top: 27px;
  bottom: 20px;
  left: 0;
}

.toolbar .togglepanel {
  color: #bbb;
  border-bottom: 1px solid #000;
  width: 100%;
  height: 21px;
  font-size: 12px;
  font-weight: 100;
  position: relative;
}

.toolbar .togglepanel:after {
  content: "";
  opacity: .5;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 2px;
  right: 2px;
}

.toolbar .togglepanel:hover {
  cursor: pointer;
}

.toolbar .togglepanel:hover:after {
  opacity: 1;
}

.toolbar .togglepanel.showpalettelist {
  text-align: left;
  border-top: 1px solid #000;
  border-bottom: none;
  padding-left: 4px;
  line-height: 20px;
}

.toolbar .tools {
  background-color: #313335;
  border: 1px solid #000;
  flex-wrap: wrap;
  place-content: flex-start space-around;
  display: flex;
}

.toolbar .button {
  text-align: center;
  border: 1px solid #0000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
}

.toolbar .button:hover, .toolbar .button.active {
  cursor: pointer;
  background-color: #3c3f41;
}

.toolbar .button.icon {
  opacity: .5;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.toolbar .button.icon:hover {
  opacity: .7;
}

.toolbar .button.icon.active {
  opacity: 1;
  background-color: #282a2c;
}

.toolbar .button.pencil {
  background-image: url("pencil.1c0ec864.svg");
  background-size: 70% 70%;
}

.toolbar .button.select {
  background-image: url("select.abb50574.svg");
}

.toolbar .button.zoomout {
  background-image: url("zoomout.4c09c249.svg");
  background-size: 70% 70%;
}

.toolbar .button.zoom {
  background-image: url("zoom.8d247e7c.svg");
  background-size: 70% 70%;
}

.toolbar .button.split {
  background-image: url("split.5527adf6.svg");
}

.toolbar .button.circle {
  background-image: url("circle.d94b880b.svg");
}

.toolbar .button.square {
  background-image: url("square.ebe2a395.svg");
}

.toolbar .button.line {
  background-image: url("line.cca08759.svg");
  background-size: 80% 80%;
}

.toolbar .button.erase {
  background-image: url("eraser.bc5214ca.svg");
  background-size: 80% 80%;
}

.toolbar .button.smudge {
  background-image: url("smudge.f0081c87.svg");
  background-size: 80% 80%;
}

.toolbar .button.stamp {
  background-image: url("stamp.5b0275ad.svg");
  background-size: 80% 80%;
}

.toolbar .button.gradient {
  background-image: url("gradient.db7272fd.svg");
}

.toolbar .button.polygonselect {
  background-image: url("poly.1c854b68.svg");
  background-size: 80% 80%;
}

.toolbar .button.pan {
  background-image: url("hand.df80803a.svg");
  background-size: 80% 80%;
}

.toolbar .button.picker {
  background-image: url("pipette_white.7e99d773.svg");
  background-size: 80% 80%;
}

.toolbar .button.floodselect {
  background-image: url("magicwand.93828dd6.svg");
  background-size: 80% 80%;
}

.toolbar .button.flood {
  background-image: url("fill.6d51c16b.svg");
  background-size: 90% 90%;
}

.toolbar .button.undo {
  background-image: url("undo.90ac1d15.svg");
  background-size: 60% 60%;
}

.toolbar .button.undo.disabled {
  opacity: .2;
}

.toolbar .button.redo {
  background-image: url("redo.e5f3d07e.svg");
  background-size: 60% 60%;
}

.toolbar .button.redo.disabled {
  opacity: .2;
}

.toolbar .brushes {
  flex-wrap: wrap;
  width: 55px;
  height: 22px;
  margin: 5px auto;
  display: flex;
}

.toolbar .brushes .brush {
  opacity: .3;
  image-rendering: optimizespeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  background-image: url("brushes.819abee4.png");
  width: 11px;
  height: 11px;
  display: block;
}

.toolbar .brushes .brush.active {
  opacity: 1;
  background-color: #000;
}

.toolbar .brushes .brush:hover {
  opacity: 1;
  background-color: #000;
  box-shadow: 0 0 2px #000;
}

.toolbar .palette {
  text-align: center;
  width: 60px;
}

.toolbar .palette .display {
  height: 72px;
  position: relative;
}

.toolbar .palette .display div {
  background-color: #000;
  border: 1px solid #000;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  left: 24px;
}

.toolbar .palette .display div.nofill {
  background-image: url("nofill.1d63b632.svg");
  background-size: 100% 100%;
}

.toolbar .palette .display div.front {
  z-index: 2;
  top: 6px;
  left: 12px;
}

.toolbar .palette .display div.button {
  opacity: .7;
  background-color: #0000;
  background-image: url("swap.e5330b47.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% 60%;
  border: 0;
  width: 30px;
  height: 30px;
  top: 42px;
  left: 30px;
}

.toolbar .palette .display div.button:hover, .toolbar .palette .display div.button.active {
  cursor: pointer;
  opacity: 1;
  background-color: #3c3f41;
}

.toolbar .palette .display div.button.transparentcolors {
  background-image: url("nofill-white.0276fc1b.svg");
  background-size: 50% 50%;
  left: 0;
}

.toolbar .palette .display input[type="color"] {
  opacity: 0;
  position: absolute;
  bottom: 30px;
}

.toolbar .palette .color {
  width: 14px;
  height: 14px;
  display: inline-block;
}

.toolbar .palettebuttons {
  background-color: #313335;
  border: 1px solid #000;
  height: 22px;
  margin: 2px auto 0;
  display: flex;
}

.toolbar .palettebuttons > div {
  text-align: center;
  color: #fff;
  opacity: .4;
  width: 100%;
}

.toolbar .palettebuttons > div:hover {
  cursor: pointer;
  opacity: 1;
  background-color: #3c3f41;
}

.toolbar .palettebuttons > div .icon {
  vertical-align: middle;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 20px;
  display: inline-block;
}

.toolbar .palettebuttons > div.edit .icon {
  background-image: url("palette.38864c59.svg");
}

.toolbar .palettebuttons > div.lock .icon {
  background-image: url("lock_open.c3862754.svg");
}

.toolbar .palettebuttons > div.lock.active {
  opacity: 1;
}

.toolbar .palettebuttons > div.lock.active .icon {
  filter: brightness(0) saturate() invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
  background-image: url("lock_closed.301e7627.svg");
}

.toolbar .palettebuttons > div.cycle .icon {
  background-image: url("cycle.c46ef19a.svg");
}

.toolbar .palettebuttons > div.cycle.active {
  opacity: 1;
}

.toolbar .palettebuttons > div.cycle.active .icon {
  filter: brightness(0) saturate() invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
  animation: 1s linear infinite spin;
}

.toolbar .palettenav {
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}

.toolbar .palettenav .nav {
  color: #bbb;
  background-color: #313335;
  border: 1px solid #000;
  height: 20px;
  margin: 2px auto 0;
  display: flex;
}

.toolbar .palettenav .nav .prev, .toolbar .palettenav .nav .next {
  opacity: .1;
  pointer-events: none;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 20px;
}

.toolbar .palettenav .nav .prev.active, .toolbar .palettenav .nav .next.active {
  opacity: .5;
  pointer-events: all;
}

.toolbar .palettenav .nav .prev:hover, .toolbar .palettenav .nav .next:hover {
  opacity: 1;
  cursor: pointer;
}

.toolbar .palettenav .nav .prev {
  transform: rotate(180deg);
}

.toolbar .palettenav .nav .page {
  text-align: center;
  width: 20px;
}

.toolbar .viewstyle {
  margin-left: 20px;
  display: flex;
}

.toolbar .viewstyle .button {
  opacity: .5;
}

.toolbar .viewstyle .button:hover, .toolbar .viewstyle .button.active {
  opacity: 1;
}

.toolbar.fill .button.circle {
  background-image: url("circle_fill.fa95accf.svg");
}

.toolbar.fill .button.square {
  background-image: url("square_fill.23091c09.svg");
}

.toolbar .palettecanvas {
  margin: 8px 0 0 4px;
}

body.withsidepanel .toolbar .togglepanel.sidebar:after, body.withpalettelist .toolbar .togglepanel.showpalettelist:after {
  transform: rotate(180deg);
}

body.presentation .toolbar {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.statusbar {
  color: #bbb;
  background-color: #313335;
  border-top: 1px solid #000;
  height: 20px;
  padding: 2px 0 0 10px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.statusbar .tooltip b {
  text-align: center;
  border: 1px solid #9a9a9a;
  height: 14px;
  padding: 0 3px;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
}

body.presentation .statusbar {
  display: none;
}

.sidepanel {
  background-color: #313335;
  border: 1px solid #000;
  width: 175px;
  display: none;
  position: absolute;
  top: 27px;
  bottom: 20px;
  left: 70px;
  overflow: auto;
}

.sidepanel .panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sidepanel .panel .inner {
  position: absolute;
  inset: 22px 0 0;
  overflow: auto;
}

.sidepanel .panel .inner small {
  color: #adadad;
  text-align: center;
  margin-top: 20px;
  font-size: 11px;
  display: block;
}

.sidepanel .panel .inner dl {
  border-bottom: 1px solid #4d4d4d;
  margin: 0;
  padding: 0;
  display: flex;
}

.sidepanel .panel .inner dl dt, .sidepanel .panel .inner dl dd {
  color: #adadad;
  background-color: #ffffff0d;
  width: 60px;
  margin: 0;
  padding: 3px 5px;
  font-size: 11px;
  display: inline-block;
}

.sidepanel .panel .inner dl dd {
  opacity: .7;
  width: 100px;
}

.sidepanel .panel .inner .layer {
  color: #bbb;
  -webkit-user-select: none;
  user-select: none;
  border-bottom: 1px solid #626262;
  height: 23px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

.sidepanel .panel .inner .layer.active {
  color: #fff;
  background-color: #ffffff1a;
}

.sidepanel .panel .inner .layer.active .mask:after {
  background-color: #46484a;
}

.sidepanel .panel .inner .layer:hover {
  cursor: pointer;
}

.sidepanel .panel .inner .layer.ghost, .sidepanel .panel .inner .layer.hidden, .sidepanel .panel .inner .layer.ghost .eye, .sidepanel .panel .inner .layer.hidden .eye {
  opacity: .4;
}

.sidepanel .panel .inner .layer.locked {
  pointer-events: none;
}

.sidepanel .panel .inner .layer .eye {
  opacity: .7;
  background-image: url("eye.2fcc794d.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 7px;
  right: 4px;
}

.sidepanel .panel .inner .layer .lock {
  opacity: .7;
  background-image: url("lock_closed.301e7627.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 3px;
  right: 24px;
}

.sidepanel .panel .inner .layer .mask {
  text-align: center;
  background-color: #c7c8c8;
  border: 1px solid #c7c8c8;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 27px;
}

.sidepanel .panel .inner .layer .mask:after {
  content: "";
  background-color: #313335;
  border-radius: 7px;
  width: 14px;
  height: 14px;
  margin: -7px 0 0 -7px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.sidepanel .panel .inner .layer .mask.active {
  background-color: #b1e00f;
  border: 1px solid #b1e00f;
  box-shadow: 0 0 2px #ff0;
}

.sidepanel .panel .inner .layer input[type="text"] {
  z-index: 10;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sidepanel .panel .inner .frame {
  opacity: .8;
  border: 1px solid #626262;
  width: 50px;
  height: 50px;
  margin: 1px;
  position: absolute;
}

.sidepanel .panel .inner .frame:hover {
  cursor: pointer;
  opacity: 1;
  border: 1px solid #fff;
}

.sidepanel .panel .inner .frame .label {
  color: #8f8f8f;
  background-color: #0006;
  padding: 4px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.sidepanel .panel .inner .frame.active {
  opacity: 1;
  box-shadow: 0 0 3px 1px #ffffffb3;
}

.sidepanel .panel .inner .frame.active .label {
  color: #bbb;
}

.sidepanel .panel .inner .frame.ghost {
  opacity: .2;
}

.sidepanel .panel h2 {
  color: #bbb;
  margin: 0;
  padding: 2px;
  font-size: 14px;
  font-weight: 100;
}

.sidepanel .panel.frames .panelcontent {
  position: absolute;
  inset: 22px 0 0;
  overflow: auto hidden;
}

.sidepanel .panel.frames .panelcontent.inactive {
  pointer-events: none;
}

.sidepanel .panel.collapsed .caption i {
  transform: rotate(0);
}

.sidepanel .panel.collapsed .inner {
  display: none;
}

.sidepanel .panel .paneltools {
  color: #bbb;
  background-color: #2d2e30;
  border-bottom: 1px solid #000000b3;
  justify-content: flex-end;
  font-size: 12px;
  display: flex;
  position: relative;
}

.sidepanel .panel .paneltools.multirow {
  height: 47px;
}

.sidepanel .panel .paneltools .button {
  opacity: .5;
  background-color: #282a2c;
  background-image: url("add.b8f3d800.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-right: 1px solid #000;
  width: 20px;
  height: 20px;
  margin-left: 2px;
  font-size: 12px;
  line-height: 20px;
}

.sidepanel .panel .paneltools .button.delete {
  background-image: url("trashcan.c726f6a6.svg");
}

.sidepanel .panel .paneltools .button:hover {
  cursor: pointer;
  opacity: 1;
}

.sidepanel .panel .paneltools .rangeselect {
  color: #9d9c9c;
  font-size: 11px;
  position: absolute;
  top: 4px;
  left: 4px;
  right: 44px;
}

.sidepanel .panel .paneltools .rangeselect input {
  width: auto;
  height: 17px;
  margin: 0 0 0 4px;
  position: absolute;
  top: 0;
  left: 40px;
  right: 0;
}

.sidepanel .panel .paneltools .rangeselect input::-webkit-slider-thumb {
  background: #9d9d9d;
  border: 1px solid #323232b3;
  width: 8px;
  height: 12px;
  margin-top: -6px;
}

.sidepanel .panel .paneltools .rangeselect input::-moz-range-thumb {
  background: #9d9d9d;
  border: 1px solid #323232b3;
  width: 8px;
  height: 12px;
  margin-top: -6px;
}

.sidepanel .panel .paneltools .rangeselect input::-moz-range-thumb {
  width: 6px;
  height: 10px;
}

.sidepanel .panel .paneltools .blendselect {
  color: #9d9c9c;
  position: absolute;
  top: 25px;
  left: 4px;
  right: 0;
}

.sidepanel .panel .paneltools .blendselect .label {
  line-height: 19px;
}

.sidepanel .panel .paneltools .blendselect select {
  color: #888;
  background-color: #181818;
  border: 1px solid #7b7b7b;
  height: 19px;
  position: absolute;
  top: 0;
  left: 40px;
  right: 4px;
}

.sidepanel .panel.brush, .sidepanel .panel.grid {
  color: #bbb;
  font-size: 12px;
}

.sidepanel .panel.brush label, .sidepanel .panel.grid label {
  opacity: .7;
  width: 110px;
  padding: 0 4px;
  line-height: 19px;
}

.sidepanel .panel.brush .rangeselect, .sidepanel .panel.grid .rangeselect {
  margin-bottom: 3px;
  display: flex;
}

.sidepanel .panel.brush .rangeselect input[type="text"], .sidepanel .panel.grid .rangeselect input[type="text"] {
  width: 30px;
  height: 19px;
  margin-left: 4px;
  margin-right: 2px;
  padding: 0 4px;
  font-size: 11px;
}

.sidepanel .panel.brush .dither, .sidepanel .panel.grid .dither {
  display: flex;
}

.sidepanel .panel.brush .patterns, .sidepanel .panel.grid .patterns {
  width: 140%;
  display: flex;
}

.sidepanel .panel.brush .patterns .pattern, .sidepanel .panel.grid .patterns .pattern {
  background-color: gray;
  background-size: contain;
  border: 1px solid #000;
  width: 100%;
  height: 20px;
  margin-right: 2px;
}

.sidepanel .panel.brush .patterns .pattern.invert.hasPattern, .sidepanel .panel.grid .patterns .pattern.invert.hasPattern {
  filter: invert();
  opacity: .5;
  background-color: #a0a0a0;
  border: 1px solid #fff;
}

.sidepanel .panel.brush .patterns .pattern.invert.hasPattern.active, .sidepanel .panel.grid .patterns .pattern.invert.hasPattern.active {
  opacity: .9;
  border-color: #600fe0;
  box-shadow: 0 0 2px #00f;
}

.sidepanel .panel.brush .patterns .pattern.p1, .sidepanel .panel.grid .patterns .pattern.p1 {
  background-image: url("dots.e83c87fb.png");
}

.sidepanel .panel.brush .patterns .pattern.p2, .sidepanel .panel.grid .patterns .pattern.p2 {
  background-image: url("cross.f25e04f5.png");
}

.sidepanel .panel.brush .patterns .pattern.p3, .sidepanel .panel.grid .patterns .pattern.p3 {
  background-image: url("grid.b69c2127.png");
}

.sidepanel .panel.brush .patterns .pattern:last-of-type, .sidepanel .panel.grid .patterns .pattern:last-of-type {
  opacity: .5;
  background-color: #0000;
  background-image: url("caret.53d268a9.svg");
  margin-right: 0;
}

.sidepanel .panel.brush .patterns .pattern:last-of-type:hover, .sidepanel .panel.grid .patterns .pattern:last-of-type:hover {
  opacity: 1;
  background-color: #0000;
}

.sidepanel .panel.brush .patterns .pattern:hover, .sidepanel .panel.grid .patterns .pattern:hover {
  cursor: pointer;
  background-color: #fff;
}

.sidepanel .panel.brush .patterns .pattern:hover.invert.hasPattern, .sidepanel .panel.grid .patterns .pattern:hover.invert.hasPattern {
  opacity: 1;
  background-color: #fff;
}

.sidepanel .panel.brush .patterns .pattern.active, .sidepanel .panel.grid .patterns .pattern.active {
  border-color: #b1e00f;
  box-shadow: 0 0 2px #ff0;
}

.sidepanel .panel.color .inner {
  overflow: hidden;
}

.sidepanel .panel.color .colorpicker {
  width: 100%;
  height: 120px;
  overflow: hidden;
}

.sidepanel .panel.color .colorpicker canvas {
  border-right: 1px solid #000;
}

.sidepanel .panel.color .colorpicker canvas:last-of-type {
  border-left: 1px solid #000;
  margin-left: 4px;
}

.sidepanel .panel.color .colorpicker .dot {
  pointer-events: none;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 0;
  left: 119px;
  box-shadow: 0 0 1px 1px #000000b3, inset 0 0 0 1px #00000080;
}

.sidepanel .panel.color .colorpicker .line {
  pointer-events: none;
  filter: drop-shadow(0 0 2px #000);
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid #fff;
  width: 0;
  height: 0;
  margin-top: -5px;
  position: absolute;
  top: 0;
  left: 144px;
}

.sidepanel .panel.color .colorpicker .line:before {
  content: "";
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: -5px;
  left: -20px;
}

.sidepanel .caption {
  color: #bbb;
  width: unset;
  -webkit-user-select: none;
  user-select: none;
  background-color: #282a2c;
  border: none;
  border-bottom: 1px solid #000;
  justify-content: flex-start;
  height: 21px;
  padding: 3px 5px 3px 16px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sidepanel .caption i {
  opacity: .7;
  background-image: url("caret.53d268a9.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  transition: transform .2s ease-in-out;
  position: absolute;
  top: 2px;
  left: 0;
  transform: rotate(90deg);
}

.sidepanel .caption .close {
  text-align: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.sidepanel .subpanel {
  color: #bbb;
  border-top: 1px solid #ffffff1a;
  border-bottom: 1px solid #00000080;
  padding: 4px;
  position: relative;
}

.sidepanel .subpanel .label {
  opacity: .7;
  margin-bottom: 4px;
  font-size: 12px;
}

.sidepanel .subpanel .value {
  position: absolute;
  top: 4px;
  right: 4px;
}

.sidepanel .subpanel .button.square {
  text-align: center;
  border: 1px solid #000;
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  top: 4px;
  right: 4px;
}

.sidepanel .subpanel .button.square:hover {
  cursor: pointer;
}

.sidepanel .subpanel .button.square.prev {
  right: 21px;
}

.sidepanel .subpanel select {
  color: #bbb;
  background-color: #0000;
  border: 1px solid #000;
  width: 100%;
  margin: 4px 0;
}

.sidepanel .subpanel select:focus {
  outline: none;
}

.sidepanel input[type="range"] {
  width: 100%;
}

body.withsidepanel .sidepanel {
  display: block;
}

body.override .sidepanel .panel .layer.system {
  display: none;
}

body.override .sidepanel .panel .layer.system + .layer {
  color: #fff;
  background-color: #ffffff1a;
}

body.override .splitpanel .panel .toolbar .toolpanel .options .checkbox.mask {
  display: none;
}

body.override .splitpanel .panel .toolbar .toolpanel .options .checkbox.pressure span:before {
  background-color: silver;
  box-shadow: inset 0 0 0 2px #000;
}

.palettelist {
  z-index: 10;
  background-color: #313335;
  border: 1px solid #000;
  width: 100px;
  display: none;
  position: absolute;
  top: 27px;
  bottom: 20px;
  left: 70px;
}

.palettelist.active {
  display: block;
  box-shadow: 1px 0 2px #0003;
}

.palettelist .caption {
  color: #bbb;
  -webkit-user-select: none;
  user-select: none;
  background-color: #282a2c;
  border-bottom: 1px solid #0006;
  height: 21px;
  padding: 3px 5px;
  font-size: 12px;
}

.palettelist .caption .close {
  text-align: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.palettelist .inner {
  position: absolute;
  inset: 20px 0 0;
  overflow: hidden auto;
}

.palettelist .group {
  color: #bbb;
  border-bottom: 1px solid #000;
  padding: 3px 5px 3px 16px;
  font-size: 12px;
  position: relative;
}

.palettelist .group:before {
  content: "";
  opacity: .7;
  background-image: url("caret.53d268a9.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  left: 1px;
}

.palettelist .group.active:before {
  transform: rotate(90deg);
}

.palettelist .group:hover {
  cursor: pointer;
  background-color: #434548;
}

.palettelist .group:hover:before {
  opacity: 1;
}

.palettelist .palette {
  clear: both;
  background-color: #313335;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-bottom: 10px;
  display: flex;
}

.palettelist .palette .caption {
  white-space: nowrap;
  background-color: #0000;
  border-top: 1px solid #434548;
  width: 100%;
  height: auto;
  font-size: 11px;
}

.palettelist .palette canvas {
  margin: 2px;
  display: block;
}

.palettelist .palette:hover {
  cursor: pointer;
  background-color: #434548;
}

.palettelist .palette:hover .caption {
  color: #fff;
}

.palettelist .palette:nth-child(2) .caption {
  border-top: none;
}

.palettelist .button {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  color: #bbb;
  border: 1px solid #8d8e8f80;
  margin: 4px 2px;
  padding: 3px 0;
  font-size: 12px;
  line-height: 14px;
}

.palettelist .button:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.splitpanel {
  position: absolute;
  inset: 27px 0 22px 70px;
}

.splitpanel .panel {
  border: 1px solid #000;
  width: calc(50% - 4px);
  position: absolute;
  top: 0;
  bottom: 0;
}

.splitpanel .panel:first-of-type {
  width: 100%;
  left: 0;
}

.splitpanel .panel:last-of-type {
  display: none;
  right: 0;
}

.splitpanel .panel .toolbar {
  width: unset;
  white-space: nowrap;
  border: none;
  border-bottom: 1px solid #000;
  justify-content: flex-start;
  height: 21px;
  display: flex;
  top: 0;
  right: 0;
}

.splitpanel .panel .toolbar .button {
  color: #bbb;
  background-color: #282a2c;
  border: none;
  border-right: 1px solid #000;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
}

.splitpanel .panel .toolbar .button.auto {
  width: auto;
  padding: 0 5px;
}

.splitpanel .panel .toolbar .button.expand {
  background-image: url("fullscreen.942fa6cf.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% 60%;
}

.splitpanel .panel .toolbar .button.closepresentation {
  display: none;
}

.splitpanel .panel .toolbar .toolpanel .options {
  color: #bbb;
  padding: 3px 2px 0;
  font-size: 12px;
  display: flex;
}

.splitpanel .panel .toolbar .toolpanel .options span.tool {
  color: #8f8f8f;
  padding: 0 4px 0 10px;
}

.splitpanel .panel .toolbar .toolpanel .options .optionsgroup, .splitpanel .panel .toolbar .toolpanel .options .flex {
  display: flex;
}

.splitpanel .panel .toolbar .toolpanel .options .checkbox {
  padding-right: 10px;
  font-size: 12px;
}

.splitpanel .panel .toolbar .toolpanel .options .checkbox label span {
  padding-left: 14px;
}

.splitpanel .panel .toolbar .toolpanel .options .checkbox label span:before {
  width: 10px;
  height: 10px;
}

.splitpanel .panel .toolbar .toolpanel .options .checkbox.mask, .splitpanel .panel .toolbar .toolpanel .options .checkbox.inline {
  margin-left: 10px;
}

.splitpanel .panel .toolbar .toolpanel .options input[type="range"] {
  width: 40px;
  height: 6px;
  margin: 0 4px;
}

.splitpanel .panel .toolbar .toolpanel .options input[type="range"]::-webkit-slider-thumb {
  width: 8px;
  height: 14px;
  margin-top: -7px;
}

.splitpanel .panel .toolbar .toolpanel .options input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #010101;
  height: 3px;
  margin-top: -2px;
}

.splitpanel .panel .toolbar .toolpanel .options label {
  font-size: 11px;
}

.splitpanel .panel .toolbar .toolpanel .options label.inline {
  margin-left: 6px;
}

.splitpanel .panel .viewport, .splitpanel .panel .windowContainer, .splitpanel .panel .tileContainer {
  touch-action: none;
  display: flex;
  position: absolute;
  inset: 21px 0 0;
  overflow: auto;
}

.splitpanel .panel .viewport.hidden, .splitpanel .panel .windowContainer.hidden, .splitpanel .panel .tileContainer.hidden {
  display: none;
}

.splitpanel .panel .windowContainer .window {
  background-image: url("frame.a7d7ba08.png");
  width: 300px;
  height: 170px;
  position: absolute;
  top: 50px;
  left: 10px;
}

.splitpanel .panel .windowContainer .window:nth-child(2) {
  top: 250px;
}

.splitpanel .panel .windowContainer .window:nth-child(3) {
  top: 450px;
}

.splitpanel .panel .windowContainer .window canvas {
  position: absolute;
  top: 18px;
  left: 4px;
}

.splitpanel .splitter {
  cursor: col-resize;
  z-index: 10;
  width: 6px;
  margin-left: 1px;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(50% - 4px);
}

.splitpanel .splitter:hover {
  background-color: #0003;
}

.splitpanel .canvaswrapper {
  margin: auto;
  display: block;
  position: relative;
}

.splitpanel .canvascontainer {
  background-image: url("checkers.37940e18.png");
  border: 1px solid #000;
  display: block;
  position: relative;
}

.splitpanel canvas {
  image-rendering: optimizespeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-touch-callout: none;
  z-index: 1;
  display: block;
  position: relative;
}

.splitpanel canvas.overlaycanvas {
  z-index: 2;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

body.shift.mousedown .splitpanel .overlaycanvas {
  display: none;
}

body.withsidepanel .splitpanel {
  left: 250px;
}

body.withfilebrowser .splitpanel {
  right: 154px;
}

body.presentation .splitpanel {
  top: 0;
  bottom: 0;
  left: 0;
}

body.presentation .splitpanel .toolbar .button {
  display: none;
}

body.presentation .splitpanel .toolbar .button.closepresentation {
  display: block;
}

body.presentation .splitpanel .toolbar .toolpanel {
  display: none;
}

.visualaids {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.visualaids .grid {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.visualaids .grid .line {
  pointer-events: none;
  position: absolute;
}

.visualaids .grid .line.vertical {
  border-left: 1px solid #fff;
  width: 1px;
  top: 0;
  bottom: 0;
}

.visualaids .grid .line.horizontal {
  border-top: 1px solid #fff;
  height: 1px;
  left: 0;
  right: 0;
}

.sizebox {
  z-index: 100;
  cursor: move;
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
}

.sizebox.hot {
  pointer-events: none;
}

.sizebox.active {
  display: block;
}

.sizebox .sizedot:first-of-type {
  cursor: nw-resize;
}

.sizebox .sizedot:nth-of-type(2) {
  cursor: n-resize;
}

.sizebox .sizedot:nth-of-type(3) {
  cursor: ne-resize;
}

.sizebox .sizedot:nth-of-type(4) {
  cursor: e-resize;
}

.sizebox .sizedot:nth-of-type(5) {
  cursor: se-resize;
}

.sizebox .sizedot:nth-of-type(6) {
  cursor: s-resize;
}

.sizebox .sizedot:nth-of-type(7) {
  cursor: sw-resize;
}

.sizebox .sizedot:nth-of-type(8) {
  cursor: w-resize;
}

.sizebox canvas {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.selectbox {
  z-index: 4;
  pointer-events: none;
  display: none;
  position: absolute;
  inset: 0;
}

.selectbox .border {
  display: none;
  position: absolute;
  inset: -1px;
}

.selectbox .border svg {
  width: 100%;
  height: 100%;
  display: none;
}

.selectbox .border svg .white, .selectbox .border svg .ants {
  stroke-width: 2px;
}

.selectbox .border.active, .selectbox .border.active svg {
  display: block;
}

.selectbox .border.filled {
  background-color: #ff000040;
  display: block;
}

.selectbox svg .white {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
  vector-effect: non-scaling-stroke;
}

.selectbox svg .filled {
  fill: #ff000040;
}

.selectbox svg .ants {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 4;
  stroke-dashoffset: 8px;
  animation: .2s linear infinite stroke;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.selectbox .content {
  position: absolute;
  inset: 0;
}

.selectbox .content .dots {
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
}

.selectbox .content .shape {
  pointer-events: none;
  z-index: 10;
  position: absolute;
  inset: 0;
}

.selectbox .content .shape svg {
  width: 100%;
}

.selectbox .content canvas {
  z-index: 9;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

.selectbox.active {
  display: block;
}

.selectbox.capture {
  pointer-events: all;
}

.sizedot {
  z-index: 2;
  background-color: #000;
  border: 1px solid #fff;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  position: absolute;
}

.sizedot:hover {
  background-color: #fff;
}

.rotatedot {
  z-index: 1;
  cursor: url("rotatenw.421d44f9.png") 0 0, auto;
  width: 20px;
  height: 20px;
  margin: -20px 0 0 -20px;
  display: none;
  position: absolute;
}

.rotatedot.d2 {
  cursor: url("rotatene.1aba2f5a.png") 0 0, auto;
  margin: -20px -20px 0 0;
}

.rotatedot.d3 {
  cursor: url("rotatese.179e8157.png") 0 0, auto;
  margin: 0 -20px -20px 0;
}

.rotatedot.d4 {
  cursor: url("rotatesw.96a7d031.png") 0 0, auto;
  margin: 0 0 -20px -20px;
}

.sizebox.canrotate .rotatedot {
  display: block;
}

.cursor {
  pointer-events: none;
  z-index: 1002;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-top: -18px;
  margin-left: -2px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.cursor.rotate {
  background-image: url("rotate1.769fc444.svg");
  margin-top: -10px;
  margin-left: -10px;
}

body.customcursor.hoverviewport, body.customcursor.hoverviewport .sizebox {
  cursor: none;
}

body.customcursor.hoverviewport .cursor {
  display: block;
}

body.hoverviewport.cursor-draw {
  cursor: url("cross.e93fbfb9.png") 12 12, auto;
}

body.hoverviewport.cursor-colorpicker {
  cursor: url("pipette.c3644f9d.png") 0 24, auto;
}

body.hoverviewport.cursor-colorpicker canvas.overlaycanvas {
  display: none;
}

body.hoverviewport.cursor-rotate {
  cursor: url("rotatene.1aba2f5a.png") 0 0, auto;
}

body.hoverviewport.cursor-select {
  cursor: crosshair;
}

body.hoverviewport.cursor-pan {
  cursor: grab;
}

body.hoverviewport.cursor-pan canvas.overlaycanvas {
  display: none;
}

body.cursor-drag, body.hoverviewport.cursor-drag {
  cursor: grabbing !important;
}

body.cursor-drag .cursor, body.hoverviewport.cursor-drag .cursor {
  display: none;
}

body.hovercanvas.pointerdown.cursor-colorpicker .cursor {
  display: block;
}

body.hovercanvas.pointerdown.cursor-colorpicker .cursor .mark {
  pointer-events: none;
  border: 8px solid green;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: -14px 0 0 -26px;
  display: none;
  position: absolute;
  box-shadow: 0 0 3px 1px #00000080, 0 0 0 1px #ffffff80, inset 0 0 3px 1px #00000080, inset 0 0 0 1px #ffffff80;
}

#dragelement {
  z-index: 1002;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

#dragelement.active {
  opacity: 1;
}

#dragelement .dragelement.box {
  color: #bbb;
  background-color: #313335;
  border: 1px solid #000;
  width: 120px;
  padding: 4px 8px;
  box-shadow: 1px 1px 2px #000000a1;
}

#dragelement .dragelement.box.frame {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 12px;
  display: flex;
}

#dragelement .dragelement.tooltip {
  white-space: nowrap;
  pointer-events: none;
  background-color: #d0c5b1;
  border: 1px solid #000;
  margin: 12px 0 0 12px;
  padding: 4px 8px;
  font-size: 12px;
}

.blanket {
  z-index: 1000;
  background-color: #000000b3;
  display: none;
  position: fixed;
  inset: 0;
}

.blanket.active {
  display: block;
}

.modalwindow {
  z-index: 1001;
  background-color: #313335;
  border: 1px solid #000;
  width: 440px;
  height: 260px;
  margin-left: -100px;
  display: none;
  position: absolute;
  top: calc(50vh - 130px);
  left: 50%;
  box-shadow: 0 0 8px #000;
}

.modalwindow .caption {
  color: #bbb;
  cursor: move;
  -webkit-user-select: none;
  user-select: none;
  background-color: #00000038;
  border-bottom: 1px solid #000;
  height: 20px;
  padding: 2px 4px;
  position: relative;
}

.modalwindow .caption .button {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #000;
  width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.modalwindow .caption .button:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.modalwindow.active {
  display: block;
}

.modalwindow .inner {
  color: #bbb;
}

.modalwindow .inner .button {
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #6a6a6a;
  margin: 2px;
  padding: 6px;
}

.modalwindow .inner .button .title {
  color: #bbb;
}

.modalwindow .inner .button .subtitle {
  color: #797979;
}

.modalwindow .inner .button:hover {
  cursor: pointer;
  background-color: #232323;
  border: 1px solid #8c8c8c;
}

.modalwindow .inner .button.large {
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.modalwindow .inner .button.highlight {
  color: #ded293;
  border: 1px solid #ded293;
}

.modalwindow .inner h3 {
  margin: 10px 0;
  padding: 0 10px;
  font-size: 13px;
}

.modalwindow .inner .panel.form {
  padding: 0 10px;
}

.modalwindow .inner .panel.form span.label {
  text-align: right;
  width: 80px;
  padding-right: 8px;
  display: inline-block;
}

.modalwindow .inner .panel.form input[type="number"] {
  width: 100px;
  margin-bottom: 4px;
  margin-right: 8px;
}

.modalwindow .inner select {
  color: #bbb;
  background-color: #0000;
  border: 1px solid #000;
  width: 100%;
  margin: 4px 0;
}

.modalwindow .inner select:focus {
  outline: none;
}

.modalwindow .inner select option {
  color: #bbb;
  background-color: #2b2b2b;
  border-radius: 0;
}

.modalwindow .inner select.resize {
  width: 100px;
  padding: 2px;
  position: absolute;
  top: 120px;
  left: 198px;
}

.modalwindow .inner .buttons {
  padding: 10px 8px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.modalwindow .inner .buttons.relative {
  position: relative;
}

.modalwindow .inner .buttons .button {
  text-align: center;
  width: 150px;
  margin: 0 2px;
  font-size: 12px;
  line-height: 20px;
}

.modalwindow .inner .buttons .button.ghost {
  opacity: .7;
}

.modalwindow .inner .about img {
  width: 100%;
  height: auto;
}

.modalwindow .inner .about .text {
  z-index: 1;
  color: #a5bac2;
  opacity: .8;
  pointer-events: none;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  left: 56px;
}

.modalwindow .inner .about .text.info {
  width: 380px;
  bottom: 80px;
}

.modalwindow .inner .about .text.copyright {
  bottom: 50px;
}

.modalwindow .inner .about .text.github {
  bottom: 24px;
}

.modalwindow .inner .about .text.link {
  pointer-events: all;
}

.modalwindow .inner .about .text.link:hover {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.modalwindow .inner .about .text.version {
  bottom: 24px;
  left: 556px;
}

.modalwindow .inner .optiondialog p {
  margin: 0;
  padding: 10px;
}

.modalwindow .inner .lock {
  opacity: .7;
  border: 1px solid #0000;
  border-left: none;
  width: 13px;
  height: 33px;
  position: absolute;
  top: 67px;
  left: 246px;
}

.modalwindow .inner .lock .link {
  opacity: .7;
  background-color: #313335;
  background-image: url("link.3ec10060.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: -8px;
}

.modalwindow .inner .lock .link:hover {
  opacity: 1;
  cursor: pointer;
}

.modalwindow .inner .lock.active {
  opacity: 1;
  border: 1px solid #8f8f8f;
  border-left: none;
}

.modalwindow .inner .anchor {
  background-color: #533030;
  border: 1px solid #000;
  flex-wrap: wrap;
  width: 92px;
  height: 92px;
  display: flex;
  position: absolute;
  top: 56px;
  left: 290px;
}

.modalwindow .inner .anchor h3 {
  position: absolute;
  top: -37px;
  left: -11px;
}

.modalwindow .inner .anchor .page {
  z-index: 1;
  background-color: #47494d;
  border: 1px solid #000;
  width: 60px;
  height: 60px;
  transition: left .2s ease-in-out, top .2s ease-in-out;
  position: absolute;
  top: 15px;
  left: 15px;
}

.modalwindow .inner .anchor .hotspot {
  z-index: 2;
  width: 30px;
  height: 30px;
  position: relative;
}

.modalwindow .inner .anchor .hotspot:hover {
  cursor: pointer;
  background-color: #ffffff21;
}

.modalwindow .inner .anchor .arrow {
  opacity: .6;
  pointer-events: none;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  transition: all .2s ease-in-out;
  position: absolute;
}

.modalwindow .inner .anchor .arrow.top {
  top: 8px;
  left: 50%;
  transform: rotate(270deg);
}

.modalwindow .inner .anchor .arrow.right {
  top: 50%;
  right: 0;
}

.modalwindow .inner .anchor .arrow.bottom {
  bottom: 0;
  left: 50%;
  transform: rotate(90deg);
}

.modalwindow .inner .anchor .arrow.left {
  top: 50%;
  left: 8px;
  transform: rotate(180deg);
}

.modalwindow .inner .anchor.top .page {
  top: -1px;
}

.modalwindow .inner .anchor.top .arrow.bottom {
  bottom: 10px;
}

.modalwindow .inner .anchor.left .page {
  left: -1px;
}

.modalwindow .inner .anchor.left .arrow.right {
  right: 10px;
}

.modalwindow .inner .anchor.right .page {
  left: 31px;
}

.modalwindow .inner .anchor.right .arrow.left {
  left: 18px;
}

.modalwindow .inner .anchor.bottom .page {
  top: 31px;
}

.modalwindow .inner .anchor.bottom .arrow.top {
  top: 18px;
}

.modalwindow .inner .quick {
  font-size: 12px;
  position: absolute;
  top: 123px;
  left: 88px;
}

.modalwindow .inner .quick .button.calc {
  text-align: center;
  width: 22px;
  padding: 2px;
  display: inline-block;
}

.modalwindow .inner .textlink {
  color: #bbb;
  text-decoration: underline;
}

.modalwindow .inner .textlink:hover {
  color: #fff;
  cursor: pointer;
}

.modalwindow .inner.full {
  position: absolute;
  inset: 20px 0 0;
}

.modalwindow .inner .palette {
  height: 281px;
  margin-top: 1px;
  position: relative;
}

.modalwindow .inner .palette.withactions .palettepanel .caption:after {
  transform: rotate(180deg);
}

.modalwindow .inner .palette.withactions .mainpanel {
  left: 201px;
}

.modalwindow .inner .palette.withactions .mainpanel .sliders {
  padding-left: 10px;
  padding-right: 10px;
}

.modalwindow .inner .palette.withactions .mainpanel .sliders input.hex {
  width: 80px;
  right: 10px;
}

.modalwindow .inner .palette.withactions .mainpanel .sliders .pixelcount {
  left: 75px;
}

.modalwindow .inner .palette.withactions .mainpanel .sliders .slider input.slider {
  width: 110px;
  margin: 4px 0 0 8px;
}

.modalwindow .inner .palette.withactions .mainpanel .sliders .slider span.label {
  width: 40px;
  font-size: 12px;
  right: 43px;
}

.modalwindow .inner .palette.withactions .mainpanel .buttons {
  right: 0;
}

.modalwindow .inner .palette.withactions .mainpanel .options {
  padding-left: 10px;
}

.modalwindow .inner .palette.withactions .actions {
  display: block;
}

.modalwindow .inner .palette .caption.sub {
  cursor: default;
  font-size: 12px;
  line-height: 17px;
}

.modalwindow .inner .palette .palettepanel {
  background-color: #292b2c;
  border-right: 1px solid #00000094;
  width: 121px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.modalwindow .inner .palette .palettepanel .caption:after {
  content: "";
  opacity: .5;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 18px;
  transition: transform .3s ease-in-out;
  position: absolute;
  right: 2px;
}

.modalwindow .inner .palette .palettepanel .caption:hover {
  cursor: pointer;
}

.modalwindow .inner .palette .palettepanel .caption:hover:after {
  opacity: 1;
}

.modalwindow .inner .palette .palettepanel canvas {
  margin: 0;
  display: block;
}

.modalwindow .inner .palette .palettepanel .highlight {
  pointer-events: none;
  border: 3px solid #000;
  width: 30px;
  height: 30px;
  margin: 21px 0 0 1px;
  position: absolute;
}

.modalwindow .inner .palette .palettepanel .highlight:before {
  content: "";
  border: 2px solid #fff;
  position: absolute;
  inset: -4px -1px -1px -4px;
}

.modalwindow .inner .palette .palettepanel .nav {
  color: #bbb;
  background-color: #313335;
  border-top: 1px solid #000;
  width: 120px;
  height: 20px;
  display: flex;
}

.modalwindow .inner .palette .palettepanel .nav .prev, .modalwindow .inner .palette .palettepanel .nav .next {
  opacity: .1;
  pointer-events: none;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 40px;
}

.modalwindow .inner .palette .palettepanel .nav .prev.active, .modalwindow .inner .palette .palettepanel .nav .next.active {
  opacity: .5;
  pointer-events: all;
}

.modalwindow .inner .palette .palettepanel .nav .prev:hover, .modalwindow .inner .palette .palettepanel .nav .next:hover {
  opacity: 1;
  cursor: pointer;
}

.modalwindow .inner .palette .palettepanel .nav .prev {
  transform: rotate(180deg);
}

.modalwindow .inner .palette .palettepanel .nav .page {
  text-align: center;
  width: 40px;
}

.modalwindow .inner .palette .actions {
  border-right: 1px solid #00000094;
  width: 80px;
  display: none;
  position: absolute;
  inset: 0 0 0 121px;
}

.modalwindow .inner .palette .actions .caption:after {
  content: "x";
  text-align: center;
  opacity: .5;
  width: 16px;
  height: 18px;
  transition: transform .3s ease-in-out;
  position: absolute;
  right: 2px;
}

.modalwindow .inner .palette .actions .caption:hover {
  cursor: pointer;
}

.modalwindow .inner .palette .actions .caption:hover:after {
  opacity: 1;
}

.modalwindow .inner .palette .actions .spacer {
  height: 20px;
}

.modalwindow .inner .palette .actions .nav {
  border-top: 1px solid #000;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalwindow .inner .palette .actions .nav div {
  opacity: .5;
  background-image: url("zoom.8d247e7c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 14px;
  width: 100%;
  height: 20px;
}

.modalwindow .inner .palette .actions .nav div:hover {
  cursor: pointer;
  opacity: 1;
}

.modalwindow .inner .palette .actions .nav div.zoomout {
  background-image: url("zoomout.4c09c249.svg");
  border-left: 1px solid #000;
}

.modalwindow .inner .palette .mainpanel {
  position: absolute;
  inset: 0 0 0 121px;
}

.modalwindow .inner .palette .mainpanel .tabs {
  width: 100%;
  display: flex;
}

.modalwindow .inner .palette .mainpanel .tabs .caption.sub {
  pointer-events: none;
  width: 100%;
  padding-left: 10px;
}

.modalwindow .inner .palette .mainpanel .tabs .caption.sub.inactive {
  opacity: .5;
  pointer-events: all;
  background-color: #313335;
}

.modalwindow .inner .palette .mainpanel .tabs .caption.sub.inactive:hover {
  cursor: pointer;
  opacity: 1;
}

.modalwindow .inner .palette .mainpanel .colorpanel {
  position: absolute;
  inset: 20px 0 0;
}

.modalwindow .inner .palette .mainpanel .rangepanel {
  display: none;
  position: absolute;
  inset: 20px 0 0;
}

.modalwindow .inner .palette .mainpanel .rangepanel .norange {
  opacity: .5;
  padding: 20px;
}

.modalwindow .inner .palette .mainpanel .rangepanel .bottom {
  padding: 4px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.modalwindow .inner .palette .mainpanel .rangepanel .bottom .button.small {
  width: auto;
  position: relative;
}

.modalwindow .inner .palette .mainpanel .rangepanel .bottom .button.small.active {
  color: #b1e00f;
  border: 1px solid #b1e00f;
  box-shadow: 0 0 2px #ff0;
}

.modalwindow .inner .palette .mainpanel .rangepanel .captions {
  color: gray;
  background-color: #262829;
  border-bottom: 1px solid #000;
  font-size: 11px;
  display: flex;
}

.modalwindow .inner .palette .mainpanel .rangepanel .captions div {
  border-left: 1px solid #313335;
  padding: 4px;
}

.modalwindow .inner .palette .mainpanel .rangepanel .captions div.a {
  border-left: none;
  padding: 4px 2px;
}

.modalwindow .inner .palette .mainpanel .rangepanel .captions div.b {
  width: 100px;
}

.modalwindow .inner .palette .mainpanel .rangepanel .ranges {
  position: absolute;
  inset: 20px 0 30px;
  overflow: auto;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range {
  border-top: 1px solid #464545;
  border-bottom: 1px solid #000;
  align-items: center;
  padding: 4px 4px 4px 8px;
  display: flex;
  overflow: auto hidden;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range.active {
  background-color: #3e4144;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range .checkbox {
  display: block;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range .slider {
  width: 60px;
  margin: 0 4px;
  display: block;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range .speed {
  width: 40px;
  margin: 0 4px 0 0;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range canvas {
  cursor: pointer;
  display: block;
}

.modalwindow .inner .palette .mainpanel .rangepanel .range canvas.active {
  box-shadow: 0 0 5px 2px #b1e00f;
}

.modalwindow .inner .palette .mainpanel .sliders {
  padding: 0 20px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs {
  margin-top: 10px;
  display: block;
  position: relative;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab {
  text-align: center;
  pointer-events: none;
  background-color: #313335;
  width: 18px;
  height: 50px;
  font-size: 11px;
  position: absolute;
  top: 53px;
  left: 0;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:before, .modalwindow .inner .palette .mainpanel .sliders .tabs .tab:after {
  content: "";
  transform-origin: 100% 0;
  border-top: 1px solid #6a6a6a;
  position: absolute;
  left: 0;
  right: 1px;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:before {
  top: 0;
  transform: rotate(-15deg);
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:after {
  bottom: -1px;
  transform: rotate(15deg);
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab span {
  border-left: 1px solid #6a6a6a;
  width: 14px;
  height: 41px;
  margin: 5px 0;
  padding: 2px 0 2px 4px;
  display: block;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:first-child {
  top: 0;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:first-child:before {
  right: 0;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab.inactive {
  opacity: .4;
  pointer-events: all;
  background-color: #0000;
  margin-left: 1px;
  transition: all .1s ease-in-out;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .tab.inactive:hover {
  cursor: pointer;
  opacity: 1;
  margin-left: -1px;
}

.modalwindow .inner .palette .mainpanel .sliders .tabs .panel {
  border: 1px solid #6a6a6a;
  margin-left: 16px;
}

.modalwindow .inner .palette .mainpanel .sliders .slider {
  margin: 8px 0;
  position: relative;
}

.modalwindow .inner .palette .mainpanel .sliders .slider span.label {
  text-align: left;
  text-transform: capitalize;
  width: 45px;
  margin-top: 5px;
  font-size: 13px;
  position: absolute;
  right: 49px;
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider {
  width: 148px;
  margin: 4px 0 0 12px;
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.red::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000 0%, #c40202 100%);
  border: none;
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.red.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #ff3a00 0%, #f0ff00 20%, #00ff1d 40%, #0007ff 60%, #ff00f1 80%, #fa0505 100%);
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.green::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000 0%, #00a402 100%);
  border: none;
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.green.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000 0%, #c40202 100%);
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.blue::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000 0%, #085db9 100%);
  border: none;
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.blue.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000 0%, #fff 100%);
}

.modalwindow .inner .palette .mainpanel .sliders .slider input.rangevalue {
  width: 40px;
  position: absolute;
  right: 4px;
}

.modalwindow .inner .palette .mainpanel .sliders input.hex {
  width: 100px;
  position: absolute;
  right: 20px;
}

.modalwindow .inner .palette .mainpanel .sliders input.masked {
  opacity: 0;
  pointer-events: none;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.modalwindow .inner .palette .mainpanel .sliders .pixelcount {
  font-size: 12px;
  position: absolute;
  top: 1px;
  left: 86px;
}

.modalwindow .inner .palette .mainpanel .button.small {
  width: 50px;
  position: absolute;
}

.modalwindow .inner .palette .mainpanel .button.small.revert {
  right: 60px;
}

.modalwindow .inner .palette .mainpanel .button.small.apply {
  right: 8px;
}

.modalwindow .inner .palette .buttons {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out;
  position: absolute;
  bottom: 72px;
  left: 0;
  right: 10px;
}

.modalwindow .inner .palette .buttons.active {
  opacity: 1;
  pointer-events: all;
}

.modalwindow .inner .palette .button.small {
  text-align: center;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 14px;
}

.modalwindow .inner .palette .button.small .contextmenu {
  position: absolute;
}

.modalwindow .inner .palette .button.small .contextmenu .item {
  text-align: left;
  background-color: #313335;
  border: 1px solid #000;
  border-radius: 2px;
  width: 100px;
  margin: 2px 0;
  padding: 2px 4px;
  font-size: 12px;
}

.modalwindow .inner .palette .button.small .contextmenu .item:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.modalwindow .inner .palette .options {
  border-top: 1px solid #000;
  padding: 5px 10px 5px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.modalwindow .inner .palette .options .checkbox {
  padding: 3px 0;
  font-size: 12px;
  position: relative;
}

.modalwindow .inner .effects {
  position: absolute;
  inset: 0;
}

.modalwindow .inner .effects .tabs {
  border-bottom: 1px solid #000;
  width: 220px;
  height: 36px;
  position: absolute;
  left: 5px;
  overflow: hidden;
}

.modalwindow .inner .effects .tabs .tab {
  text-align: center;
  opacity: .5;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #000;
  width: 90px;
  height: 24px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: -3px;
  left: 5px;
}

.modalwindow .inner .effects .tabs .tab:nth-of-type(2) {
  left: 100px;
}

.modalwindow .inner .effects .tabs .tab:hover, .modalwindow .inner .effects .tabs .tab.active {
  cursor: pointer;
  opacity: 1;
  background-color: #fff3;
  bottom: 0;
}

.modalwindow .inner .effects .sliders {
  display: none;
  position: absolute;
  top: 40px;
  left: 10px;
}

.modalwindow .inner .effects .sliders.active {
  display: block;
}

.modalwindow .inner .effects .slider {
  margin: 4px 0;
  position: relative;
}

.modalwindow .inner .effects .slider label {
  text-transform: capitalize;
  font-size: 12px;
  display: block;
}

.modalwindow .inner .effects .slider input[type="range"] {
  z-index: 10;
  width: 200px;
  margin-top: -2px;
  position: relative;
}

.modalwindow .inner .effects .slider input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: linear-gradient(90deg, #000 0%, #c8c8c8 100%);
  border: 1px solid #010101;
  width: 100%;
  height: 4px;
}

.modalwindow .inner .effects .slider input[type="range"]::-moz-range-track {
  cursor: pointer;
  background: linear-gradient(90deg, #000 0%, #c8c8c8 100%);
  border: 1px solid #010101;
  width: 100%;
  height: 2px;
}

.modalwindow .inner .effects .slider input[type="range"].saturation::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #7f7f7f 0%, #f50a0a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].saturation::-moz-range-track {
  background: linear-gradient(90deg, #7f7f7f 0%, #f50a0a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].contrast::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #7f7f7f 0%, #000 100%);
}

.modalwindow .inner .effects .slider input[type="range"].contrast::-moz-range-track {
  background: linear-gradient(90deg, #7f7f7f 0%, #000 100%);
}

.modalwindow .inner .effects .slider input[type="range"].hue::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #ff3a00 0%, #f0ff00 20%, #00ff1d 40%, #0007ff 60%, #ff00f1 80%, #fa0505 100%);
}

.modalwindow .inner .effects .slider input[type="range"].hue::-moz-range-track {
  background: linear-gradient(90deg, #ff3a00 0%, #f0ff00 20%, #00ff1d 40%, #0007ff 60%, #ff00f1 80%, #fa0505 100%);
}

.modalwindow .inner .effects .slider input[type="range"].sepia::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #313335 0%, #8a4f38 100%);
}

.modalwindow .inner .effects .slider input[type="range"].sepia::-moz-range-track {
  background: linear-gradient(90deg, #313335 0%, #8a4f38 100%);
}

.modalwindow .inner .effects .slider input[type="range"].cyan::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #0af5f5 0%, #f50a0a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].cyan::-moz-range-track {
  background: linear-gradient(90deg, #0af5f5 0%, #f50a0a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].magenta::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #f50af5 0%, #0af50a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].magenta::-moz-range-track {
  background: linear-gradient(90deg, #f50af5 0%, #0af50a 100%);
}

.modalwindow .inner .effects .slider input[type="range"].yellow::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #f5f50a 0%, #0a0af5 100%);
}

.modalwindow .inner .effects .slider input[type="range"].yellow::-moz-range-track {
  background: linear-gradient(90deg, #f5f50a 0%, #0a0af5 100%);
}

.modalwindow .inner .effects .slider input[type="text"] {
  width: 40px;
  padding: 2px 4px;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}

.modalwindow .inner .effects .alchemy {
  width: 200px;
  display: none;
  position: absolute;
  top: 40px;
  left: 10px;
}

.modalwindow .inner .effects .alchemy.active {
  display: block;
}

.modalwindow .inner .effects .alchemy .recipe {
  background-image: url("flask.8d832741.svg");
  background-position: 2px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border-bottom: 1px solid #000;
  padding: 6px 6px 6px 24px;
  font-size: 12px;
}

.modalwindow .inner .effects .alchemy .recipe:hover {
  cursor: pointer;
  background-color: #3c3f41;
}

.modalwindow .inner .effects .code {
  position: absolute;
  top: 250px;
  left: 10px;
  right: 10px;
}

.modalwindow .inner .effects .code code {
  white-space: pre;
  color: #8f8f8f;
  background-color: #1c1c1c;
  border: 1px solid #000;
  height: 170px;
  font-family: monospace;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.modalwindow .inner .effects .code code:focus {
  outline: none;
}

.modalwindow .inner .effects .code code .string {
  color: #a1e46d;
}

.modalwindow .inner .effects .code code .number {
  color: #4176ba;
}

.modalwindow .inner .effects .code code .html {
  color: #e4d95f;
}

.modalwindow .inner .effects .code code .reserved {
  color: #c87531;
}

.modalwindow .inner .effects .code code .js {
  color: #6de4d1;
}

.modalwindow .inner .effects .code code .globals {
  color: #ab66b7;
}

.modalwindow .inner .effects .code code .comment {
  color: #aaa;
}

.modalwindow .inner .effects .code code .method {
  color: #f9c26b;
}

.modalwindow .inner .effects .code code .source {
  color: #64b969;
}

.modalwindow .inner .effects .code code .target {
  color: #64b9a9;
}

.modalwindow .inner .effects .code .button {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 176px;
  left: 0;
}

.modalwindow .inner .effects .code .params {
  z-index: 1;
  background-color: #313335;
  width: 256px;
  height: 206px;
  position: absolute;
  top: -242px;
  right: 0;
}

.modalwindow .inner .effects .code .params h3 {
  padding-left: 0;
}

.modalwindow .inner .effects .code .params .slider {
  margin-left: 20px;
  margin-right: 36px;
}

.modalwindow .inner .effects .code .params .slider label, .modalwindow .inner .effects .code .params .slider input[type="text"] {
  font-size: 11px;
}

.modalwindow .inner .effects .code .params .slider input[type="text"] {
  padding: 1px 2px;
}

.modalwindow .inner .effects .code .params .slider input[type="range"]::-webkit-slider-thumb {
  height: 12px;
  margin-top: -5px;
}

.modalwindow .inner .effects .code .params .slider input[type="range"]::-ms-thumb {
  height: 12px;
}

.modalwindow .inner .effects .code .params .slider input[type="range"]::-moz-range-thumb {
  height: 10px;
}

.modalwindow .inner .effects .code .params.columns .slider {
  width: 110px;
  margin-left: 10px;
  margin-right: 0;
  display: inline-block;
}

.modalwindow .inner .effects .code .params.columns .slider.main, .modalwindow .inner .effects .code .params.columns .slider.main input[type="range"] {
  width: 230px;
}

.modalwindow .inner .effects .code .params.columns .slider input[type="range"] {
  width: 110px;
}

.modalwindow .inner .effects .code .params.columns .slider input[type="text"] {
  width: 30px;
}

.modalwindow .inner .effects .previewpanel {
  position: absolute;
  top: 12px;
  left: 240px;
}

.modalwindow .inner .effects .previewpanel .preview {
  border: 1px solid #000;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}

.modalwindow .inner .effects .buttons {
  justify-content: flex-end;
  left: 0;
  right: 0;
}

.modalwindow .inner .effects .buttons .button.left {
  position: absolute;
  left: 10px;
}

.modalwindow .inner .ditheredit .preset {
  image-rendering: optimizespeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  background-color: #6b6b6b;
  background-size: 50% 50%;
  border: 1px solid #000;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.modalwindow .inner .ditheredit .preset:hover {
  cursor: pointer;
  background-color: #bbb;
  border: 1px solid #b1e00f;
}

.modalwindow .inner .ditheredit .preset.p0 {
  background-image: url("dots.e83c87fb.png");
}

.modalwindow .inner .ditheredit .preset.p1 {
  background-image: url("cross.f25e04f5.png");
}

.modalwindow .inner .ditheredit .preset.p2 {
  background-image: url("grid.b69c2127.png");
}

.modalwindow .inner .ditheredit .preset.p3 {
  background-image: url("cross2.68ef69df.png");
}

.modalwindow .inner .ditheredit .preset.p4 {
  background-image: url("lines_hor.50ecfbef.png");
}

.modalwindow .inner .ditheredit .preset.p5 {
  background-image: url("lines_ver.9b254f9c.png");
}

.modalwindow .inner .ditheredit .preset.p6 {
  background-image: url("lines_diag.82ebecbd.png");
}

.modalwindow .inner .ditheredit .preset.p7 {
  background-image: url("longgrid.f01a37d4.png");
}

.modalwindow .inner .ditheredit .preset.user canvas {
  width: 100%;
  height: 100%;
}

.modalwindow .inner .ditheredit .editpreset, .modalwindow .inner .ditheredit .previewpreset, .modalwindow .inner .ditheredit .presets {
  border: 1px solid #000;
  width: 202px;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
}

.modalwindow .inner .ditheredit .editpreset {
  width: 242px;
  left: 210px;
}

.modalwindow .inner .ditheredit .previewpreset {
  left: 455px;
}

.modalwindow .inner .ditheredit .previewpreset canvas {
  background-image: url("gradient.5e97b931.png");
  background-size: 100% 100%;
}

.modalwindow .inner .ditheredit h2 {
  border-bottom: 1px solid #000;
  margin: 0;
  padding: 4px;
  font-size: 12px;
  font-weight: normal;
}

.modalwindow .inner .ditheredit .subtoolbar {
  text-align: right;
  border-top: 1px solid #000;
  padding: 0;
  font-size: 12px;
  position: relative;
}

.modalwindow .inner .ditheredit .subtoolbar .button {
  text-align: center;
  white-space: nowrap;
  padding: 4px 9px;
  display: inline-block;
}

.modalwindow .inner .ditheredit .subtoolbar .button.small {
  width: 24px;
  padding: 4px;
}

.modalwindow .inner .ditheredit .subtoolbar .button.active {
  color: #b2970e;
  background-color: #413e28;
  border-color: #b2970e;
}

.modalwindow .inner .ditheredit .subtoolbar .button.hidden {
  display: none;
}

.modalwindow .inner .ditheredit .subtoolbar .button.left {
  max-width: 49%;
  position: absolute;
  left: 0;
}

.modalwindow .inner .saveform {
  padding: 10px;
}

.modalwindow .inner .saveform h4 {
  margin: 0 0 4px;
  padding: 0;
  font-weight: normal;
}

.modalwindow .inner .saveform h4.amiga, .modalwindow .inner .saveform h4.general {
  background-image: url("amigatick.7fcc7413.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 20px;
}

.modalwindow .inner .saveform h4.general {
  background-image: url("image.5c1c6345.svg");
  padding-left: 22px;
}

.modalwindow .inner .saveform input {
  width: 100%;
  margin-bottom: 10px;
}

.modalwindow .inner .saveform .platform {
  vertical-align: top;
  width: 50%;
  display: inline-block;
}

.modalwindow .inner .saveform .platform .button {
  border: none;
  padding: 5px 5px 5px 40px;
  position: relative;
}

.modalwindow .inner .saveform .platform .button .icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
}

.modalwindow .inner .saveform .platform .button .icon.png {
  background-image: url("png.27d340bf.svg");
}

.modalwindow .inner .saveform .platform .button .icon.json {
  background-image: url("json.0511ef61.svg");
}

.modalwindow .inner .saveform .platform .button .icon.psd {
  background-image: url("psd.ceb7f499.svg");
}

.modalwindow .inner .saveform .platform .button .icon.mui {
  background-image: url("mui.2ef8e98c.png");
}

.modalwindow .inner .saveform .platform .button .icon.os3 {
  background-image: url("os3.986b4789.png");
}

.modalwindow .inner .saveform .platform .button .icon.os4 {
  background-image: url("os4.8d2cfbfe.png");
}

.modalwindow .inner .saveform .platform .button .icon.iff {
  background-image: url("iff.6d4ec690.png");
}

.modalwindow .inner .saveform .platform .button .icon.adf {
  background-image: url("floppy.bf3f1bc6.png");
}

.modalwindow .inner .saveform .platform .button .info {
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  background-color: #4d5052;
  border: 1px solid #000;
  width: 200px;
  min-height: 40px;
  padding: 4px 4px 4px 8px;
  transition: opacity .2s ease-in-out, left .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 140px;
}

.modalwindow .inner .saveform .platform .button .info:before {
  content: "";
  border: 10px solid #0000;
  border-right-color: #000;
  position: absolute;
  top: 10px;
  left: -21px;
}

.modalwindow .inner .saveform .platform .button .info:after {
  content: "";
  border: 10px solid #0000;
  border-right-color: #4d5052;
  position: absolute;
  top: 10px;
  left: -20px;
}

.modalwindow .inner .saveform .platform .button:hover .info {
  opacity: 1;
  left: 160px;
}

.modalwindow .inner .saveform .platform .button.more {
  min-width: 60px;
  padding: 5px 8px;
  position: absolute;
  bottom: 10px;
}

.modalwindow .inner .saveform .platform .button.more:after {
  content: "";
  opacity: .5;
  background-image: url("caret.53d268a9.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 17px;
  position: absolute;
  right: 2px;
}

.modalwindow .inner .saveform .platform.amiga .button .info {
  left: unset;
  transition: opacity .2s ease-in-out, right .2s ease-in-out;
  right: 140px;
}

.modalwindow .inner .saveform .platform.amiga .button .info:before {
  left: unset;
  right: -21px;
  transform: rotate(180deg);
}

.modalwindow .inner .saveform .platform.amiga .button .info:after {
  left: unset;
  right: -20px;
  transform: rotate(180deg);
}

.modalwindow .inner .saveform .platform.amiga .button:hover .info {
  right: 160px;
}

.modalwindow .inner .saveform .moremenu {
  z-index: 10;
  background-color: #2b2b2b;
  border-left: 1px solid #000;
  width: 220px;
  display: none;
  position: absolute;
  top: 20px;
  bottom: 0;
  right: 0;
}

.modalwindow .inner .saveform .moremenu .item {
  border-bottom: 1px solid #000;
  padding: 5px 5px 5px 44px;
  position: relative;
}

.modalwindow .inner .saveform .moremenu .item .subtitle {
  color: #797979;
  padding: 4px 0;
  font-size: 13px;
}

.modalwindow .inner .saveform .moremenu .item:hover {
  cursor: pointer;
  background-color: #232323;
}

.modalwindow .inner .saveform .moremenu .item:hover .info {
  opacity: 1;
  right: 220px;
}

.modalwindow .inner .saveform .moremenu .item:after {
  content: "";
  background-image: url("layers.ddb83cc8.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 34px;
  height: 34px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.modalwindow .inner .saveform .moremenu .item.mask:after {
  background-image: url("layers_mask.d07ac727.svg");
}

.modalwindow .inner .saveform .moremenu .item.index:after {
  background-image: url("pixelgrid.1d18a737.svg");
}

.modalwindow .inner .saveform .moremenu .item.loading:after {
  display: none;
}

.modalwindow .inner .saveform .moremenu .item .spinner {
  width: 32px;
  height: 32px;
  top: 8px;
  left: 30px;
}

.modalwindow .inner .saveform .moremenu .item .info {
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  background-color: #232323;
  border: 1px solid #000;
  width: 170px;
  min-height: 40px;
  padding: 4px 4px 4px 8px;
  transition: opacity .2s ease-in-out, right .2s ease-in-out;
  position: absolute;
  top: 25px;
  right: 120px;
  box-shadow: 0 0 20px #00000082;
}

.modalwindow .inner .saveform .moremenu .item .info:before {
  content: "";
  border: 10px solid #0000;
  border-right-color: #000;
  position: absolute;
  top: 10px;
  right: -21px;
  transform: rotate(180deg);
}

.modalwindow .inner .saveform .moremenu .item .info:after {
  content: "";
  border: 10px solid #0000;
  border-right-color: #232323;
  position: absolute;
  top: 10px;
  right: -20px;
  transform: rotate(180deg);
}

.modalwindow .inner .saveform.hasmore .moremenu {
  display: block;
}

.modalwindow .inner .saveform.hasmore .button.more:after {
  transform: rotate(180deg);
}

.modalwindow .inner .saveform.hasmore input {
  width: calc(100% - 220px);
}

.modalwindow .inner .saveoverlay {
  z-index: 10;
  background-color: #3d3f41;
  position: absolute;
  inset: 20px 1px 1px;
}

.modalwindow .inner .saveoverlay .info {
  text-align: center;
  padding: 50px 10px 10px;
}

.modalwindow .inner .saveoverlay .info b {
  padding: 20px 0;
  font-size: 20px;
  display: block;
}

.modalwindow .inner .saveoverlay .spinner, .modalwindow .inner .saveoverlay.loading .info {
  display: none;
}

.modalwindow .inner .saveoverlay.loading .buttons {
  pointer-events: none;
  opacity: .5;
}

.modalwindow .inner .saveoverlay.loading .spinner {
  display: block;
}

.notificationbox {
  z-index: 1000;
  color: #bbb;
  opacity: 0;
  pointer-events: none;
  background-color: #1a1a1a;
  border: 1px solid #3b3b3b;
  width: 250px;
  height: 80px;
  margin-left: -200px;
  font-size: 13px;
  transition: all .2s ease-in-out;
  position: fixed;
  bottom: 40px;
  left: 20px;
}

.notificationbox .title {
  background-color: #101010;
  border-bottom: 1px solid #3b3b3b;
  padding: 4px 10px;
}

.notificationbox .text {
  padding: 4px 10px;
}

.notificationbox.active {
  opacity: 1;
  pointer-events: all;
  margin-left: 0;
  display: block;
}

.filebrowser {
  background-color: #313335;
  border: 1px solid #000;
  width: 150px;
  display: none;
  position: absolute;
  top: 27px;
  bottom: 22px;
  right: 0;
  overflow: hidden auto;
}

.filebrowser.active {
  display: block;
}

.filebrowser .caption {
  color: #bbb;
  border-bottom: 1px solid #000;
  height: 21px;
  padding: 4px 5px 2px 20px;
  font-size: 12px;
}

.filebrowser .caption .close {
  text-align: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.filebrowser .disk {
  color: #bbb;
  background-image: url("disk.0cb7de66.svg");
  background-position: 2px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border-bottom: 1px solid #000;
  padding: 2px 2px 2px 21px;
  font-size: 11px;
  line-height: 20px;
  position: relative;
}

.filebrowser .disk .download {
  cursor: pointer;
  opacity: .7;
  background-image: url("download.72713743.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.filebrowser .disk .download:hover {
  opacity: 1;
}

.filebrowser .listitem {
  color: #bbb;
  border-bottom: 1px solid #000;
  padding: 2px 2px 2px 21px;
  position: relative;
}

.filebrowser .listitem.folder {
  background-image: url("folder.85a88315.svg");
  background-position: 2px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}

.filebrowser .listitem.file {
  opacity: .5;
}

.filebrowser .listitem.file:before {
  content: "";
  background-color: gray;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.filebrowser .listitem.file.image {
  opacity: 1;
}

.filebrowser .listitem.file.image:before {
  background-color: #71b471;
}

.filebrowser .listitem:hover {
  cursor: pointer;
  color: #fff;
  background-color: #3c3f41;
}

.uae {
  z-index: 1000;
  background-color: #282a2c;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 20px #000;
}

.uae .caption {
  color: #bbb;
  cursor: move;
  background-image: url("amigatick.7fcc7413.png");
  background-position: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 1px solid #000;
  height: 20px;
  padding: 2px 2px 2px 24px;
  font-size: 12px;
}

.uae .caption .close {
  text-align: center;
  cursor: pointer;
  opacity: .7;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.uae .caption .close:hover {
  opacity: 1;
}

.uae .resizer {
  cursor: nwse-resize;
  border: 10px solid #313335;
  border-color: #0000 #313335 #313335 #0000;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.uae iframe {
  border: none;
  width: 100%;
  height: calc(100% - 21px);
}

.uae.dragging iframe {
  pointer-events: none;
}

@media only screen and (width <= 600px) {
  .menu {
    border: none;
    width: 100px;
    height: auto;
    top: 27px;
  }

  .menu .hamburger {
    background-image: url("hamburger.e695a915.svg");
    background-position: 6px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    width: 200px;
    height: 27px;
    padding: 6px 4px 2px 40px;
    display: block;
    position: absolute;
    top: -27px;
  }

  .menu a.main {
    border: 1px solid #000;
    border-bottom: none;
    padding: 2px 10px;
    font-size: 13px;
    display: none;
  }

  .menu a.main:last-child {
    border-bottom: 1px solid #000;
  }

  .menu a.main .sub {
    top: 0;
    left: 100px;
  }

  .menu a.main .sub a {
    border-bottom: 1px solid #0006;
    padding: 2px 20px 2px 10px;
    font-size: 13px;
  }

  .menu a.main .sub a:last-child {
    border-bottom: none;
  }

  .menu a.main .sub a.wide {
    padding: 2px 80px 2px 10px;
  }

  .menu a.main .sub a.ultrawide {
    padding: 2px 100px 2px 10px;
  }

  .menu.active a.main {
    display: block;
  }
}

/*# sourceMappingURL=index.ae6475c0.css.map */
