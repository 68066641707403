.palettelist{
  position: absolute;
  left: 70px;
  top: 27px;
  bottom: 20px;
  border: 1px solid black;
  width: 100px;
  background-color: #313335;
  z-index: 10;
  display: none;

  &.active{
    display: block;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
  }

  .caption{
    background-color: #282A2C;
    color: #BBBBBB;
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    height: 21px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    user-select: none;

    .close{
      position: absolute;
      height: 20px;
      width: 20px;
      line-height: 20px;
      right: 0;
      top: 0;
      text-align: center;
      cursor: pointer;
    }
  }

  .inner{
    position: absolute;
    left:0;
    right: 0;
    top: 20px;
    bottom: 0;
    overflow: auto;
    overflow-x: hidden;
  }

  .group{
    color: #BBBBBB;
    padding: 3px 5px 3px 16px;
    position: relative ;
    font-size: 12px;
    border-bottom: 1px solid black;

    &:before{
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: url("../_img/caret.svg");
      background-repeat: no-repeat;
      background-size: contain;
      left: 1px;
      top: 2px;
      opacity: 0.7;
    }

    &.active{
      &:before{
        transform: rotate(90deg);
      }
    }

    &:hover{
        cursor: pointer;
        background-color: #434548;

      &:before{
        opacity: 1;
      }
    }
  }

  .palette{
    padding-bottom: 10px;
    clear: both;
    background-color:#313335;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .caption{
      background-color:transparent;
      border-top: 1px solid rgb(67, 69, 72);
      width: 100%;
      height: auto;
      font-size: 11px;
      white-space: nowrap;
    }

    canvas{
      display: block;
      margin: 2px;
    }

    &:hover{
      background-color: #434548;
      cursor: pointer;

      .caption{
        color: white;
      }
    }

    &:nth-child(2){
      .caption{
        border-top:none;
      }
    }
  }

  .button{
    line-height: 14px;
    text-align: center;
    border: 1px solid rgba(141, 142, 143, 0.5);
    font-size: 12px;
    user-select: none;
    color: $menu-text-color;
    padding: 3px 0;
    margin: 4px 2px;

    &:hover{
      background-color: $panel-background-active;
      cursor: pointer;
    }
  }
}