@import "var";

.toolbar{
  position: absolute;
  left: 0;
  top: 27px;
  width: 64px;
  bottom: 20px;
  user-select: none;

  .togglepanel{
    height: 21px;
    width: 100%;
    border-bottom: 1px solid black;
    position: relative;
    color: $menu-text-color;
    font-size: 12px;
    font-weight: 100;

    &:after{
      content: "";
      position: absolute;
      right: 2px;
      top: 2px;
      background-position: center center;
      width: 16px;
      height: 16px;
      background-image: url("../_img/caret.svg");
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.5;
      transition: transform 0.3s ease-in-out;
    }

    &:hover{
      cursor: pointer;
      &:after{
        opacity: 1;
      }
    }

    &.showpalettelist{
      border-top: 1px solid black;
      border-bottom: none;
      line-height: 20px;
      text-align: left;
      padding-left: 4px;
    }
  }

  .tools{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    background-color: $panel-background-color;
    border: 1px solid black;
  }

  .button{
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0);
    line-height: 30px;
    text-align: center;


    &:hover,
    &.active{
      background-color: $panel-background-active;
      cursor: pointer;
    }

    &.icon{
      background-repeat: no-repeat;
      background-position: center center;
      opacity: 0.5;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-crisp-edges;
      image-rendering: pixelated;
      image-rendering: crisp-edges;
      background-size: 20px 20px;
      color: white;

      &:hover{
        opacity: 0.7;
      }

      &.active{
        opacity: 1;
        background-color: $button-background-dark;
      }
    }

    &.pencil{
      background-image: url("../_img/pencil.svg");
      background-size: 70% 70%;
    }

    &.select{
      background-image: url("../_img/select.svg");
    }

    &.zoomout{
      background-image: url("../_img/zoomout.svg");
      background-size: 70% 70%;
    }

    &.zoom{
      background-image: url("../_img/zoom.svg");
      background-size: 70% 70%;
    }

    &.split{
      background-image: url("../_img/split.svg");
    }

    &.circle{
      background-image: url("../_img/circle.svg");
    }

    &.square{
      background-image: url("../_img/square.svg");
    }

    &.line{
      background-image: url("../_img/line.svg");
      background-size: 80% 80%;
    }

    &.erase{
      background-image: url("../_img/eraser.svg");
      background-size: 80% 80%;
    }

    &.smudge{
      background-image: url("../_img/smudge.svg");
      background-size: 80% 80%;
    }

    &.stamp{
      background-image: url("../_img/stamp.svg");
      background-size: 80% 80%;
    }

    &.gradient{
      background-image: url("../_img/gradient.svg");
    }

    &.polygonselect{
      background-image: url("../_img/poly.svg");
      background-size: 80% 80%;
    }

    &.pan{
      background-image: url("../_img/hand.svg");
      background-size: 80% 80%;
    }

    &.picker{
      background-image: url("../_img/pipette_white.svg");
      background-size: 80% 80%;
    }

    &.floodselect{
      background-image: url("../_img/magicwand.svg");
      background-size: 80% 80%;
    }

    &.flood{
      background-image: url("../_img/fill.svg");
      background-size: 90% 90%;
    }

    &.undo{
      background-image: url("../_img/undo.svg");
      background-size: 60% 60%;
      &.disabled{
        opacity: 0.2;
      }
    }

    &.redo{
      background-image: url("../_img/redo.svg");
      background-size: 60% 60%;
      &.disabled{
        opacity: 0.2;
      }
    }
  }


  .brushes{
    width: 55px;
    height: 22px;
    margin: 5px auto;
    display: flex;
    flex-wrap: wrap;

    .brush{
      width: 11px;
      height: 11px;
      background-image: url("../_img/brushes.png");
      opacity: 0.3;
      display: block;
      image-rendering: optimizeSpeed;
      image-rendering: optimize-contrast;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: pixelated;
      -ms-interpolation-mode: nearest-neighbor;

      &.active{
        opacity: 1;
        background-color: black;
      }

      &:hover{
        opacity: 1;
        background-color: black;
        box-shadow: 0 0 2px black;
      }
    }
  }

  .palette{
    width: 60px;
    text-align: center;

    .display{
      height: 72px;
      position: relative;

      div{
        position: absolute;
        width: 24px;
        height: 24px;
        border: 1px solid black;
        left: 24px;
        top: 16px;
        background-color: black;

        &.nofill{
          background-image: url("../_img/nofill.svg");
          background-size: 100% 100%;
        }

        &.front{
          z-index: 2;
          left: 12px;
          top: 6px;
        }

        &.button{
          width: 30px;
          height: 30px;
          left: 30px;
          top: 42px;
          background-color:transparent;
          border: 0;
          background-image: url("../_img/swap.svg");
          background-repeat: no-repeat;
          background-size: 60% 60%;
          background-position: center center;
          opacity: 0.7;

          &:hover,
          &.active{
            background-color: $panel-background-active;
            cursor: pointer;
            opacity: 1;
          }

          &.transparentcolors{
            left: 0;
            background-image: url("../_img/nofill-white.svg");
            background-size: 50% 50%;
          }
        }
      }

      input[type="color"]{
        position: absolute;
        opacity: 0;
        bottom: 30px;
      }
    }

    .color{
      width: 14px;
      height: 14px;
      display: inline-block;
    }
  }

  .palettebuttons{
    height: 22px;
    background-color: $panel-background-color;
    border: 1px solid black;
    margin: 2px auto 0 auto;
    display: flex;

    >div{
      width: 100%;
      text-align: center;
      color: white;
      opacity: 0.4;

      &:hover{
        background-color: $panel-background-active;
        cursor: pointer;
        opacity: 1;
      }

      &.active{

      }

      .icon{
        width: 16px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        background-color: transparent;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: center center;
      }


      &.edit{
        .icon{
          background-image: url("../_img/palette.svg");
        }
      }

      &.lock{
        .icon{
          background-image: url("../_img/lock_open.svg");
        }

        &.active{
          opacity: 1;
          .icon{
            background-image: url("../_img/lock_closed.svg");
            filter: brightness(0) saturate(100%) invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
          }
        }
      }

      &.cycle{
        .icon{
          background-image: url("../_img/cycle.svg");
        }

        &.active{
          opacity: 1;
          .icon{
            animation: spin 1s infinite linear;
            filter: brightness(0) saturate(100%) invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
          }
        }

      }
    }
  }

  .palettenav{
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    .nav{
      height: 20px;
      background-color: $panel-background-color;
      border: 1px solid black;
      margin: 2px auto 0 auto;
      display: flex;
      color: $menu-text-color;

      .prev,.next{
        width: 20px;
        background-image: url("../_img/caret.svg");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.1;
        pointer-events: none;

        &.active{
          opacity: 0.5;
          pointer-events: all;
        }

        &:hover{
          opacity: 1;
          cursor: pointer;
        }
      }

      .prev{
        transform: rotate(180deg);
      }

      .page{
        width: 20px;
        text-align: center;
      }
    }
  }

  .viewstyle{
    display: flex;
    margin-left: 20px;

    .button{
      opacity: 0.5;

      &:hover{
        opacity: 1;
      }

      &.active{
        opacity: 1;
      }
    }

  }

  &.fill{
    .button{
      &.circle{
        background-image: url("../_img/circle_fill.svg");
      }

      &.square{
        background-image: url("../_img/square_fill.svg");
      }
    }
  }

  .palettecanvas{
    margin: 8px 0 0 4px;
  }
}

body.withsidepanel{
  .toolbar {
    .togglepanel.sidebar {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

body.withpalettelist{
  .toolbar {
    .togglepanel.showpalettelist {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
}

body.presentation{
  .toolbar {
    display: none;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}