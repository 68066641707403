/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #8c8c8c #454545;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: #000000;
}

*::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
    border-radius: 10px;
    border: 2px solid #2b2b2b;
}

*::-webkit-scrollbar-thumb:hover{
    background-color: #626262;
}

::-webkit-scrollbar-corner {
    background: #000000;
}