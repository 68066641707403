html {
  box-sizing: border-box;
  overflow: hidden;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: #2B2B2B;
  font-family: sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 1px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.container {
  position: relative;
  height: 100%;
  background-color: #2B2B2B;
}
.container.fuzzy {
  pointer-events: none;
  filter: blur(2px);
}

h1.error {
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 100;
  margin: 50px auto;
  width: 50%;
}
h1.error a {
  color: white;
}

.spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top: 5px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8c8c8c #454545;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 10px;
  border: 2px solid #2b2b2b;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #626262;
}

::-webkit-scrollbar-corner {
  background: #000000;
}

.checkbox {
  display: block;
}
.checkbox label {
  position: relative;
}
.checkbox label span {
  padding: 2px 0 2px 18px;
  line-height: 14px;
  white-space: nowrap;
  color: #BBBBBB;
}
.checkbox label span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 14px;
  height: 14px;
  background-color: #2b2b2b;
  border: 1px solid #8a8a8a;
}
.checkbox label:hover {
  cursor: pointer;
}
.checkbox label:hover span {
  color: #d7d7d7;
}
.checkbox label:hover span:before {
  border: 1px solid #e8e8e8;
}
.checkbox input {
  opacity: 0;
  position: absolute;
}
.checkbox input:checked + span:before {
  background-color: #c0c0c0;
  box-shadow: inset 0 0 0px 2px black;
}

input[type=number],
input[type=text] {
  display: inline-block;
  background-color: #2b2b2b;
  border: 1px solid #6A6A6A;
  color: #BBBBBB;
  padding: 4px;
}
input[type=number]:focus,
input[type=text]:focus {
  outline: none;
}

.yesno {
  display: flex;
  width: 70px;
  height: 18px;
  border: 1px solid #6A6A6A;
  margin: 2px 2px 0 0;
  padding-top: 1px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}
.yesno .option {
  width: 50px;
  text-align: center;
  opacity: 0.5;
  position: relative;
  z-index: 2;
}
.yesno .option:nth-child(2) {
  opacity: 1;
  color: black;
}
.yesno:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 30px;
  background-color: #BBBBBB;
  transition: left 0.3s ease-in-out;
}
.yesno:hover {
  cursor: pointer;
}
.yesno:hover .option {
  opacity: 1;
}
.yesno:hover .option:nth-child(2) {
  opacity: 1 !important;
}
.yesno.selected .option:nth-child(1) {
  opacity: 1;
  color: black;
}
.yesno.selected .option:nth-child(2) {
  opacity: 0.5;
  color: inherit;
}
.yesno.selected:before {
  left: 0;
}

input[type=range] {
  margin: 0;
  background-color: transparent;
  -webkit-appearance: none;
  box-sizing: border-box;
  height: 21px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  background: #c8c8c8;
  border: 1px solid #010101;
  width: 100%;
  height: 2px;
  cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
  margin-top: -8.6px;
  width: 8px;
  height: 16px;
  background: #c8c8c8;
  border: 1px solid rgba(50, 50, 50, 0.7);
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #d5d5d5;
}

input[type=range]::-moz-range-track {
  background: #c8c8c8;
  border: 1px solid #010101;
  width: 100%;
  height: 0;
  margin-top: -2px;
  cursor: pointer;
}

input[type=range]::-moz-range-thumb {
  width: 6px;
  height: 14px;
  background: #c8c8c8;
  border: 1px solid rgba(50, 50, 50, 0.7);
  border-radius: 0;
  cursor: pointer;
  margin-top: -2px;
}

input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 8px 0;
  color: transparent;
  width: 100%;
  height: 2px;
  cursor: pointer;
}

input[type=range]::-ms-fill-lower {
  background: #bbbbbb;
  border: 1px solid #010101;
}

input[type=range]::-ms-fill-upper {
  background: #c8c8c8;
  border: 1px solid #010101;
}

input[type=range]::-ms-thumb {
  width: 8px;
  height: 16px;
  background: #c8c8c8;
  border: 1px solid rgba(50, 50, 50, 0.7);
  cursor: pointer;
  margin-top: 0;
}

input[type=range]:focus::-ms-fill-lower {
  background: #c8c8c8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #d5d5d5;
}

.menu {
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid black;
  height: 25px;
  background-color: #313335;
  color: #BBBBBB;
  z-index: 1000;
  user-select: none;
  white-space: nowrap;
}
.menu .hamburger {
  display: none;
}
.menu a.main {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  line-height: 23px;
  font-size: 13px;
}
.menu a.main .sub {
  left: 0;
  margin-top: 0;
  position: absolute;
  background-color: #313335;
  color: #BBBBBB;
  border: 1px solid black;
  display: none;
}
.menu a.main .sub a {
  display: block;
  padding: 0 24px 0 10px;
  font-size: 13px;
  line-height: 23px;
  white-space: nowrap;
  position: relative;
}
.menu a.main .sub a.wide {
  padding: 0 70px 0 10px;
}
.menu a.main .sub a.wide.ultra {
  padding: 0 90px 0 10px;
}
.menu a.main .sub a.caret:after {
  content: "";
  position: absolute;
  right: 2px;
  width: 16px;
  height: 23px;
  background-image: url("../_img/caret.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}
.menu a.main .sub a.checked:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  bottom: 0;
  width: 14px;
  background-image: url("../_img/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.menu a.main .sub a:hover {
  background-color: #3C3F41;
  cursor: pointer;
}
.menu a.main .sub a:hover .subsub {
  display: block;
}
.menu a.main .sub a .shortkey {
  position: absolute;
  right: 6px;
  top: 1px;
  color: #8f8f8f;
  font-size: 11px;
}
.menu a.main .sub.checkable a {
  padding: 0 24px 0 20px;
}
.menu a.main .sub .subsub {
  position: absolute;
  background-color: #313335;
  border: 1px solid black;
  z-index: 100;
  top: 0;
  left: 50%;
  display: none;
}
.menu a.main:hover, .menu a.main.active {
  background-color: #3C3F41;
  cursor: pointer;
}
.menu a.main.active .sub {
  display: block;
}

body.presentation .menu {
  display: none;
}

.contextmenu {
  position: absolute;
  z-index: 999;
  border: 1px solid black;
  background-color: #313335;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: none;
  pointer-events: none;
  color: #BBBBBB;
}
.contextmenu.active {
  opacity: 1;
  display: block;
  pointer-events: all;
}
.contextmenu .contextmenuitem {
  display: block;
  padding: 0 24px 0 10px;
  font-size: 13px;
  line-height: 23px;
  white-space: nowrap;
  position: relative;
}
.contextmenu .contextmenuitem:hover {
  background-color: #3C3F41;
  cursor: pointer;
}

.toolbar {
  position: absolute;
  left: 0;
  top: 27px;
  width: 64px;
  bottom: 20px;
  user-select: none;
}
.toolbar .togglepanel {
  height: 21px;
  width: 100%;
  border-bottom: 1px solid black;
  position: relative;
  color: #BBBBBB;
  font-size: 12px;
  font-weight: 100;
}
.toolbar .togglepanel:after {
  content: "";
  position: absolute;
  right: 2px;
  top: 2px;
  background-position: center center;
  width: 16px;
  height: 16px;
  background-image: url("../_img/caret.svg");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: transform 0.3s ease-in-out;
}
.toolbar .togglepanel:hover {
  cursor: pointer;
}
.toolbar .togglepanel:hover:after {
  opacity: 1;
}
.toolbar .togglepanel.showpalettelist {
  border-top: 1px solid black;
  border-bottom: none;
  line-height: 20px;
  text-align: left;
  padding-left: 4px;
}
.toolbar .tools {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  background-color: #313335;
  border: 1px solid black;
}
.toolbar .button {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0);
  line-height: 30px;
  text-align: center;
}
.toolbar .button:hover, .toolbar .button.active {
  background-color: #3C3F41;
  cursor: pointer;
}
.toolbar .button.icon {
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  background-size: 20px 20px;
  color: white;
}
.toolbar .button.icon:hover {
  opacity: 0.7;
}
.toolbar .button.icon.active {
  opacity: 1;
  background-color: #282A2C;
}
.toolbar .button.pencil {
  background-image: url("../_img/pencil.svg");
  background-size: 70% 70%;
}
.toolbar .button.select {
  background-image: url("../_img/select.svg");
}
.toolbar .button.zoomout {
  background-image: url("../_img/zoomout.svg");
  background-size: 70% 70%;
}
.toolbar .button.zoom {
  background-image: url("../_img/zoom.svg");
  background-size: 70% 70%;
}
.toolbar .button.split {
  background-image: url("../_img/split.svg");
}
.toolbar .button.circle {
  background-image: url("../_img/circle.svg");
}
.toolbar .button.square {
  background-image: url("../_img/square.svg");
}
.toolbar .button.line {
  background-image: url("../_img/line.svg");
  background-size: 80% 80%;
}
.toolbar .button.erase {
  background-image: url("../_img/eraser.svg");
  background-size: 80% 80%;
}
.toolbar .button.smudge {
  background-image: url("../_img/smudge.svg");
  background-size: 80% 80%;
}
.toolbar .button.stamp {
  background-image: url("../_img/stamp.svg");
  background-size: 80% 80%;
}
.toolbar .button.gradient {
  background-image: url("../_img/gradient.svg");
}
.toolbar .button.polygonselect {
  background-image: url("../_img/poly.svg");
  background-size: 80% 80%;
}
.toolbar .button.pan {
  background-image: url("../_img/hand.svg");
  background-size: 80% 80%;
}
.toolbar .button.picker {
  background-image: url("../_img/pipette_white.svg");
  background-size: 80% 80%;
}
.toolbar .button.floodselect {
  background-image: url("../_img/magicwand.svg");
  background-size: 80% 80%;
}
.toolbar .button.flood {
  background-image: url("../_img/fill.svg");
  background-size: 90% 90%;
}
.toolbar .button.undo {
  background-image: url("../_img/undo.svg");
  background-size: 60% 60%;
}
.toolbar .button.undo.disabled {
  opacity: 0.2;
}
.toolbar .button.redo {
  background-image: url("../_img/redo.svg");
  background-size: 60% 60%;
}
.toolbar .button.redo.disabled {
  opacity: 0.2;
}
.toolbar .brushes {
  width: 55px;
  height: 22px;
  margin: 5px auto;
  display: flex;
  flex-wrap: wrap;
}
.toolbar .brushes .brush {
  width: 11px;
  height: 11px;
  background-image: url("../_img/brushes.png");
  opacity: 0.3;
  display: block;
  image-rendering: optimizeSpeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
.toolbar .brushes .brush.active {
  opacity: 1;
  background-color: black;
}
.toolbar .brushes .brush:hover {
  opacity: 1;
  background-color: black;
  box-shadow: 0 0 2px black;
}
.toolbar .palette {
  width: 60px;
  text-align: center;
}
.toolbar .palette .display {
  height: 72px;
  position: relative;
}
.toolbar .palette .display div {
  position: absolute;
  width: 24px;
  height: 24px;
  border: 1px solid black;
  left: 24px;
  top: 16px;
  background-color: black;
}
.toolbar .palette .display div.nofill {
  background-image: url("../_img/nofill.svg");
  background-size: 100% 100%;
}
.toolbar .palette .display div.front {
  z-index: 2;
  left: 12px;
  top: 6px;
}
.toolbar .palette .display div.button {
  width: 30px;
  height: 30px;
  left: 30px;
  top: 42px;
  background-color: transparent;
  border: 0;
  background-image: url("../_img/swap.svg");
  background-repeat: no-repeat;
  background-size: 60% 60%;
  background-position: center center;
  opacity: 0.7;
}
.toolbar .palette .display div.button:hover, .toolbar .palette .display div.button.active {
  background-color: #3C3F41;
  cursor: pointer;
  opacity: 1;
}
.toolbar .palette .display div.button.transparentcolors {
  left: 0;
  background-image: url("../_img/nofill-white.svg");
  background-size: 50% 50%;
}
.toolbar .palette .display input[type=color] {
  position: absolute;
  opacity: 0;
  bottom: 30px;
}
.toolbar .palette .color {
  width: 14px;
  height: 14px;
  display: inline-block;
}
.toolbar .palettebuttons {
  height: 22px;
  background-color: #313335;
  border: 1px solid black;
  margin: 2px auto 0 auto;
  display: flex;
}
.toolbar .palettebuttons > div {
  width: 100%;
  text-align: center;
  color: white;
  opacity: 0.4;
}
.toolbar .palettebuttons > div:hover {
  background-color: #3C3F41;
  cursor: pointer;
  opacity: 1;
}
.toolbar .palettebuttons > div .icon {
  width: 16px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
}
.toolbar .palettebuttons > div.edit .icon {
  background-image: url("../_img/palette.svg");
}
.toolbar .palettebuttons > div.lock .icon {
  background-image: url("../_img/lock_open.svg");
}
.toolbar .palettebuttons > div.lock.active {
  opacity: 1;
}
.toolbar .palettebuttons > div.lock.active .icon {
  background-image: url("../_img/lock_closed.svg");
  filter: brightness(0) saturate(100%) invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
}
.toolbar .palettebuttons > div.cycle .icon {
  background-image: url("../_img/cycle.svg");
}
.toolbar .palettebuttons > div.cycle.active {
  opacity: 1;
}
.toolbar .palettebuttons > div.cycle.active .icon {
  animation: spin 1s infinite linear;
  filter: brightness(0) saturate(100%) invert(91%) sepia(54%) saturate(6141%) hue-rotate(14deg) brightness(92%) contrast(90%);
}
.toolbar .palettenav {
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
}
.toolbar .palettenav .nav {
  height: 20px;
  background-color: #313335;
  border: 1px solid black;
  margin: 2px auto 0 auto;
  display: flex;
  color: #BBBBBB;
}
.toolbar .palettenav .nav .prev, .toolbar .palettenav .nav .next {
  width: 20px;
  background-image: url("../_img/caret.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  pointer-events: none;
}
.toolbar .palettenav .nav .prev.active, .toolbar .palettenav .nav .next.active {
  opacity: 0.5;
  pointer-events: all;
}
.toolbar .palettenav .nav .prev:hover, .toolbar .palettenav .nav .next:hover {
  opacity: 1;
  cursor: pointer;
}
.toolbar .palettenav .nav .prev {
  transform: rotate(180deg);
}
.toolbar .palettenav .nav .page {
  width: 20px;
  text-align: center;
}
.toolbar .viewstyle {
  display: flex;
  margin-left: 20px;
}
.toolbar .viewstyle .button {
  opacity: 0.5;
}
.toolbar .viewstyle .button:hover {
  opacity: 1;
}
.toolbar .viewstyle .button.active {
  opacity: 1;
}
.toolbar.fill .button.circle {
  background-image: url("../_img/circle_fill.svg");
}
.toolbar.fill .button.square {
  background-image: url("../_img/square_fill.svg");
}
.toolbar .palettecanvas {
  margin: 8px 0 0 4px;
}

body.withsidepanel .toolbar .togglepanel.sidebar:after {
  transform: rotate(180deg);
}

body.withpalettelist .toolbar .togglepanel.showpalettelist:after {
  transform: rotate(180deg);
}

body.presentation .toolbar {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.statusbar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
  background-color: #313335;
  color: #BBBBBB;
  font-size: 12px;
  padding: 2px 0 0 10px;
  border-top: 1px solid black;
  overflow: hidden;
}
.statusbar .tooltip b {
  font-weight: normal;
  display: inline-block;
  border: 1px solid #9a9a9a;
  font-size: 10px;
  height: 14px;
  padding: 0 3px;
  text-align: center;
}

body.presentation .statusbar {
  display: none;
}

.sidepanel {
  position: absolute;
  left: 70px;
  top: 27px;
  bottom: 20px;
  border: 1px solid black;
  width: 175px;
  background-color: #313335;
  display: none;
  overflow: auto;
}
.sidepanel .panel {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.sidepanel .panel .inner {
  position: absolute;
  top: 22px;
  bottom: 0;
  overflow: auto;
  left: 0;
  right: 0;
}
.sidepanel .panel .inner small {
  font-size: 11px;
  color: #adadad;
  text-align: center;
  margin-top: 20px;
  display: block;
}
.sidepanel .panel .inner dl {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #4d4d4d;
  display: flex;
}
.sidepanel .panel .inner dl dt,
.sidepanel .panel .inner dl dd {
  display: inline-block;
  color: #adadad;
  font-size: 11px;
  padding: 3px 5px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.05);
  width: 60px;
}
.sidepanel .panel .inner dl dd {
  opacity: 0.7;
  width: 100px;
}
.sidepanel .panel .inner .layer {
  padding: 5px;
  color: #BBBBBB;
  border-bottom: 1px solid #626262;
  font-size: 12px;
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  height: 23px;
}
.sidepanel .panel .inner .layer.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
.sidepanel .panel .inner .layer.active .mask:after {
  background-color: #46484A;
}
.sidepanel .panel .inner .layer:hover {
  cursor: pointer;
}
.sidepanel .panel .inner .layer.ghost, .sidepanel .panel .inner .layer.hidden {
  opacity: 0.4;
}
.sidepanel .panel .inner .layer.ghost .eye, .sidepanel .panel .inner .layer.hidden .eye {
  opacity: 0.4;
}
.sidepanel .panel .inner .layer.locked {
  pointer-events: none;
}
.sidepanel .panel .inner .layer .eye {
  position: absolute;
  right: 4px;
  top: 7px;
  width: 16px;
  height: 16px;
  background-image: url("../_img/eye.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.sidepanel .panel .inner .layer .lock {
  position: absolute;
  right: 24px;
  top: 3px;
  width: 16px;
  height: 16px;
  background-image: url("../_img/lock_closed.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.sidepanel .panel .inner .layer .mask {
  position: absolute;
  right: 27px;
  top: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid #C7C8C8;
  background-color: #C7C8C8;
  text-align: center;
}
.sidepanel .panel .inner .layer .mask:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin: -7px 0 0 -7px;
  background-color: #313335;
}
.sidepanel .panel .inner .layer .mask.active {
  border: 1px solid #b1e00f;
  background-color: #b1e00f;
  box-shadow: 0 0 2px yellow;
}
.sidepanel .panel .inner .layer input[type=text] {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  font-size: 12px;
  right: 0;
}
.sidepanel .panel .inner .frame {
  width: 50px;
  height: 50px;
  border: 1px solid #626262;
  margin: 1px;
  position: absolute;
  opacity: 0.8;
}
.sidepanel .panel .inner .frame:hover {
  border: 1px solid white;
  cursor: pointer;
  opacity: 1;
}
.sidepanel .panel .inner .frame .label {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: #8f8f8f;
  font-size: 12px;
  padding: 4px;
}
.sidepanel .panel .inner .frame.active {
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.7);
  opacity: 1;
}
.sidepanel .panel .inner .frame.active .label {
  color: #BBBBBB;
}
.sidepanel .panel .inner .frame.ghost {
  opacity: 0.2;
}
.sidepanel .panel h2 {
  font-size: 14px;
  color: #BBBBBB;
  font-weight: 100;
  padding: 2px;
  margin: 0;
}
.sidepanel .panel.frames .panelcontent {
  position: absolute;
  left: 0;
  right: 0;
  top: 22px;
  bottom: 0;
  overflow: auto;
  overflow-y: hidden;
}
.sidepanel .panel.frames .panelcontent.inactive {
  pointer-events: none;
}
.sidepanel .panel.collapsed .caption i {
  transform: rotate(0deg);
}
.sidepanel .panel.collapsed .inner {
  display: none;
}
.sidepanel .panel .paneltools {
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  background-color: #2D2E30;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #BBBBBB;
  position: relative;
}
.sidepanel .panel .paneltools.multirow {
  height: 47px;
}
.sidepanel .panel .paneltools .button {
  height: 20px;
  width: 20px;
  margin-left: 2px;
  font-size: 12px;
  line-height: 20px;
  border: none;
  border-right: 1px solid black;
  background-color: #282A2C;
  background-image: url("../_img/add.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
}
.sidepanel .panel .paneltools .button.delete {
  background-image: url("../_img/trashcan.svg");
}
.sidepanel .panel .paneltools .button:hover {
  cursor: pointer;
  opacity: 1;
}
.sidepanel .panel .paneltools .rangeselect {
  position: absolute;
  left: 4px;
  top: 4px;
  right: 44px;
  font-size: 11px;
  color: #9d9c9c;
}
.sidepanel .panel .paneltools .rangeselect input {
  position: absolute;
  margin: 0 0 0 4px;
  height: 17px;
  left: 40px;
  top: 0;
  right: 0;
  width: auto;
}
.sidepanel .panel .paneltools .rangeselect input::-webkit-slider-thumb, .sidepanel .panel .paneltools .rangeselect input::-moz-range-thumb {
  margin-top: -6px;
  width: 8px;
  height: 12px;
  background: #9d9d9d;
  border: 1px solid rgba(50, 50, 50, 0.7);
}
.sidepanel .panel .paneltools .rangeselect input::-moz-range-thumb {
  width: 6px;
  height: 10px;
}
.sidepanel .panel .paneltools .blendselect {
  position: absolute;
  left: 4px;
  top: 25px;
  right: 0;
  color: #9d9c9c;
}
.sidepanel .panel .paneltools .blendselect .label {
  line-height: 19px;
}
.sidepanel .panel .paneltools .blendselect select {
  position: absolute;
  right: 4px;
  top: 0;
  height: 19px;
  left: 40px;
  background-color: #181818;
  color: #888888;
  border: 1px solid #7b7b7b;
}
.sidepanel .panel.brush, .sidepanel .panel.grid {
  color: #BBBBBB;
  font-size: 12px;
}
.sidepanel .panel.brush label, .sidepanel .panel.grid label {
  opacity: 0.7;
  line-height: 19px;
  padding: 0 4px;
  width: 110px;
}
.sidepanel .panel.brush .rangeselect, .sidepanel .panel.grid .rangeselect {
  display: flex;
  margin-bottom: 3px;
}
.sidepanel .panel.brush .rangeselect input[type=text], .sidepanel .panel.grid .rangeselect input[type=text] {
  padding: 0 4px;
  width: 30px;
  height: 19px;
  margin-left: 4px;
  margin-right: 2px;
  font-size: 11px;
}
.sidepanel .panel.brush .dither, .sidepanel .panel.grid .dither {
  display: flex;
}
.sidepanel .panel.brush .patterns, .sidepanel .panel.grid .patterns {
  display: flex;
  width: 140%;
}
.sidepanel .panel.brush .patterns .pattern, .sidepanel .panel.grid .patterns .pattern {
  width: 100%;
  height: 20px;
  margin-right: 2px;
  border: 1px solid black;
  background-color: #808080;
  background-size: contain;
}
.sidepanel .panel.brush .patterns .pattern.invert.hasPattern, .sidepanel .panel.grid .patterns .pattern.invert.hasPattern {
  filter: invert(1);
  border: 1px solid white;
  background-color: #A0A0A0;
  opacity: 0.5;
}
.sidepanel .panel.brush .patterns .pattern.invert.hasPattern.active, .sidepanel .panel.grid .patterns .pattern.invert.hasPattern.active {
  border-color: #600fe0;
  box-shadow: 0 0 2px blue;
  opacity: 0.9;
}
.sidepanel .panel.brush .patterns .pattern.p1, .sidepanel .panel.grid .patterns .pattern.p1 {
  background-image: url("../_img/patterns/dots.png");
}
.sidepanel .panel.brush .patterns .pattern.p2, .sidepanel .panel.grid .patterns .pattern.p2 {
  background-image: url("../_img/patterns/cross.png");
}
.sidepanel .panel.brush .patterns .pattern.p3, .sidepanel .panel.grid .patterns .pattern.p3 {
  background-image: url("../_img/patterns/grid.png");
}
.sidepanel .panel.brush .patterns .pattern:last-of-type, .sidepanel .panel.grid .patterns .pattern:last-of-type {
  margin-right: 0;
  background-color: transparent;
  background-image: url("../_img/caret.svg");
  opacity: 0.5;
}
.sidepanel .panel.brush .patterns .pattern:last-of-type:hover, .sidepanel .panel.grid .patterns .pattern:last-of-type:hover {
  background-color: transparent;
  opacity: 1;
}
.sidepanel .panel.brush .patterns .pattern:hover, .sidepanel .panel.grid .patterns .pattern:hover {
  cursor: pointer;
  background-color: white;
}
.sidepanel .panel.brush .patterns .pattern:hover.invert.hasPattern, .sidepanel .panel.grid .patterns .pattern:hover.invert.hasPattern {
  background-color: white;
  opacity: 1;
}
.sidepanel .panel.brush .patterns .pattern.active, .sidepanel .panel.grid .patterns .pattern.active {
  border-color: #b1e00f;
  box-shadow: 0 0 2px yellow;
}
.sidepanel .panel.color .inner {
  overflow: hidden;
}
.sidepanel .panel.color .colorpicker {
  height: 120px;
  width: 100%;
  overflow: hidden;
}
.sidepanel .panel.color .colorpicker canvas {
  border-right: 1px solid black;
}
.sidepanel .panel.color .colorpicker canvas:last-of-type {
  border-left: 1px solid black;
  margin-left: 4px;
}
.sidepanel .panel.color .colorpicker .dot {
  position: absolute;
  pointer-events: none;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 50%;
  margin: -5px 0 0 -5px;
  left: 119px;
  top: 0;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.sidepanel .panel.color .colorpicker .line {
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-right: 5px solid white;
  left: 144px;
  filter: drop-shadow(0px 0px 2px black);
  top: 0;
  margin-top: -5px;
}
.sidepanel .panel.color .colorpicker .line:before {
  content: "";
  position: absolute;
  left: -20px;
  top: -5px;
  width: 0;
  height: 0;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid white;
}
.sidepanel .caption {
  background-color: #282A2C;
  color: #BBBBBB;
  padding: 3px 5px 3px 16px;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: unset;
  height: 21px;
  border: none;
  border-bottom: 1px solid black;
  justify-content: flex-start;
  user-select: none;
}
.sidepanel .caption i {
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  background-image: url("../_img/caret.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  transition: transform 0.2s ease-in-out;
  opacity: 0.7;
}
.sidepanel .caption .close {
  position: absolute;
  height: 20px;
  width: 20px;
  line-height: 20px;
  right: 0;
  top: 0;
  text-align: center;
  cursor: pointer;
}
.sidepanel .subpanel {
  color: #BBBBBB;
  padding: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  position: relative;
}
.sidepanel .subpanel .label {
  opacity: 0.7;
  font-size: 12px;
  margin-bottom: 4px;
}
.sidepanel .subpanel .value {
  position: absolute;
  right: 4px;
  top: 4px;
}
.sidepanel .subpanel .button.square {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid black;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
}
.sidepanel .subpanel .button.square:hover {
  cursor: pointer;
}
.sidepanel .subpanel .button.square.prev {
  right: 21px;
}
.sidepanel .subpanel select {
  color: #BBBBBB;
  width: 100%;
  border: 1px solid black;
  background-color: transparent;
  margin: 4px 0;
}
.sidepanel .subpanel select:focus {
  outline: none;
}
.sidepanel input[type=range] {
  width: 100%;
}

body.withsidepanel .sidepanel {
  display: block;
}

body.override .sidepanel .panel .layer.system {
  display: none;
}
body.override .sidepanel .panel .layer.system + .layer {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
body.override .splitpanel .panel .toolbar .toolpanel .options .checkbox.mask {
  display: none;
}
body.override .splitpanel .panel .toolbar .toolpanel .options .checkbox.pressure span:before {
  background-color: #c0c0c0;
  box-shadow: inset 0 0 0px 2px black;
}

.palettelist {
  position: absolute;
  left: 70px;
  top: 27px;
  bottom: 20px;
  border: 1px solid black;
  width: 100px;
  background-color: #313335;
  z-index: 10;
  display: none;
}
.palettelist.active {
  display: block;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
}
.palettelist .caption {
  background-color: #282A2C;
  color: #BBBBBB;
  padding: 3px 5px 3px 5px;
  font-size: 12px;
  height: 21px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  user-select: none;
}
.palettelist .caption .close {
  position: absolute;
  height: 20px;
  width: 20px;
  line-height: 20px;
  right: 0;
  top: 0;
  text-align: center;
  cursor: pointer;
}
.palettelist .inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
}
.palettelist .group {
  color: #BBBBBB;
  padding: 3px 5px 3px 16px;
  position: relative;
  font-size: 12px;
  border-bottom: 1px solid black;
}
.palettelist .group:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url("../_img/caret.svg");
  background-repeat: no-repeat;
  background-size: contain;
  left: 1px;
  top: 2px;
  opacity: 0.7;
}
.palettelist .group.active:before {
  transform: rotate(90deg);
}
.palettelist .group:hover {
  cursor: pointer;
  background-color: #434548;
}
.palettelist .group:hover:before {
  opacity: 1;
}
.palettelist .palette {
  padding-bottom: 10px;
  clear: both;
  background-color: #313335;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.palettelist .palette .caption {
  background-color: transparent;
  border-top: 1px solid rgb(67, 69, 72);
  width: 100%;
  height: auto;
  font-size: 11px;
  white-space: nowrap;
}
.palettelist .palette canvas {
  display: block;
  margin: 2px;
}
.palettelist .palette:hover {
  background-color: #434548;
  cursor: pointer;
}
.palettelist .palette:hover .caption {
  color: white;
}
.palettelist .palette:nth-child(2) .caption {
  border-top: none;
}
.palettelist .button {
  line-height: 14px;
  text-align: center;
  border: 1px solid rgba(141, 142, 143, 0.5);
  font-size: 12px;
  user-select: none;
  color: #BBBBBB;
  padding: 3px 0;
  margin: 4px 2px;
}
.palettelist .button:hover {
  background-color: #3C3F41;
  cursor: pointer;
}

.splitpanel {
  position: absolute;
  top: 27px;
  left: 70px;
  right: 0;
  bottom: 22px;
}
.splitpanel .panel {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% - 4px);
  border: 1px solid black;
}
.splitpanel .panel:first-of-type {
  left: 0;
  width: 100%;
}
.splitpanel .panel:last-of-type {
  right: 0;
  display: none;
}
.splitpanel .panel .toolbar {
  top: 0;
  right: 0;
  width: unset;
  height: 21px;
  border: none;
  border-bottom: 1px solid black;
  justify-content: flex-start;
  display: flex;
  white-space: nowrap;
}
.splitpanel .panel .toolbar .button {
  height: 20px;
  width: 20px;
  color: #BBBBBB;
  font-size: 12px;
  line-height: 20px;
  border: none;
  border-right: 1px solid black;
  background-color: #282A2C;
}
.splitpanel .panel .toolbar .button.auto {
  width: auto;
  padding: 0 5px;
}
.splitpanel .panel .toolbar .button.expand {
  background-image: url("../_img/fullscreen.svg");
  background-size: 60% 60%;
  background-position: center center;
  background-repeat: no-repeat;
}
.splitpanel .panel .toolbar .button.closepresentation {
  display: none;
}
.splitpanel .panel .toolbar .toolpanel .options {
  display: flex;
  padding: 3px 2px 0 2px;
  color: #BBBBBB;
  font-size: 12px;
}
.splitpanel .panel .toolbar .toolpanel .options span.tool {
  padding: 0px 4px 0 10px;
  color: #8f8f8f;
}
.splitpanel .panel .toolbar .toolpanel .options .optionsgroup {
  display: flex;
}
.splitpanel .panel .toolbar .toolpanel .options .flex {
  display: flex;
}
.splitpanel .panel .toolbar .toolpanel .options .checkbox {
  font-size: 12px;
  padding-right: 10px;
}
.splitpanel .panel .toolbar .toolpanel .options .checkbox label span {
  padding-left: 14px;
}
.splitpanel .panel .toolbar .toolpanel .options .checkbox label span:before {
  width: 10px;
  height: 10px;
}
.splitpanel .panel .toolbar .toolpanel .options .checkbox.mask {
  margin-left: 10px;
}
.splitpanel .panel .toolbar .toolpanel .options .checkbox.inline {
  margin-left: 10px;
}
.splitpanel .panel .toolbar .toolpanel .options input[type=range] {
  margin: 0 4px 0 4px;
  width: 40px;
  height: 6px;
}
.splitpanel .panel .toolbar .toolpanel .options input[type=range]::-webkit-slider-thumb {
  margin-top: -7px;
  width: 8px;
  height: 14px;
}
.splitpanel .panel .toolbar .toolpanel .options input[type=range]::-webkit-slider-runnable-track {
  background: #010101;
  height: 3px;
  margin-top: -2px;
  cursor: pointer;
}
.splitpanel .panel .toolbar .toolpanel .options label {
  font-size: 11px;
}
.splitpanel .panel .toolbar .toolpanel .options label.inline {
  margin-left: 6px;
}
.splitpanel .panel .viewport,
.splitpanel .panel .windowContainer,
.splitpanel .panel .tileContainer {
  position: absolute;
  top: 21px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  touch-action: none;
}
.splitpanel .panel .viewport.hidden,
.splitpanel .panel .windowContainer.hidden,
.splitpanel .panel .tileContainer.hidden {
  display: none;
}
.splitpanel .panel .windowContainer .window {
  width: 300px;
  height: 170px;
  background-image: url("../_img/frame.png");
  position: absolute;
  top: 50px;
  left: 10px;
}
.splitpanel .panel .windowContainer .window:nth-child(2) {
  top: 250px;
}
.splitpanel .panel .windowContainer .window:nth-child(3) {
  top: 450px;
}
.splitpanel .panel .windowContainer .window canvas {
  position: absolute;
  top: 18px;
  left: 4px;
}
.splitpanel .splitter {
  position: absolute;
  left: calc(50% - 4px);
  margin-left: 1px;
  top: 0;
  bottom: 0;
  width: 6px;
  cursor: col-resize;
  z-index: 10;
  display: none;
}
.splitpanel .splitter:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.splitpanel .canvaswrapper {
  display: block;
  margin: auto;
  position: relative;
}
.splitpanel .canvascontainer {
  display: block;
  position: relative;
  border: 1px solid black;
  background-image: url("../_img/checkers.png");
}
.splitpanel canvas {
  image-rendering: optimizeSpeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-touch-callout: none;
  display: block;
  position: relative;
  z-index: 1;
}
.splitpanel canvas.overlaycanvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  pointer-events: none;
}

body.shift.mousedown .splitpanel .overlaycanvas {
  display: none;
}

body.withsidepanel .splitpanel {
  left: 250px;
}

body.withfilebrowser .splitpanel {
  right: 154px;
}

body.presentation .splitpanel {
  left: 0;
  top: 0;
  bottom: 0;
}
body.presentation .splitpanel .toolbar .button {
  display: none;
}
body.presentation .splitpanel .toolbar .button.closepresentation {
  display: block;
}
body.presentation .splitpanel .toolbar .toolpanel {
  display: none;
}

.visualaids {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  right: 0;
  bottom: 0;
}
.visualaids .grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.visualaids .grid .line {
  position: absolute;
  pointer-events: none;
}
.visualaids .grid .line.vertical {
  top: 0;
  bottom: 0;
  border-left: 1px solid white;
  width: 1px;
}
.visualaids .grid .line.horizontal {
  left: 0;
  right: 0;
  border-top: 1px solid white;
  height: 1px;
}

.sizebox {
  position: absolute;
  width: 10px;
  height: 10px;
  /*background-color: rgba(255, 0, 0, 0.24);*/
  z-index: 100;
  display: none;
  cursor: move;
}
.sizebox.hot {
  pointer-events: none;
}
.sizebox.active {
  display: block;
}
.sizebox .sizedot:nth-of-type(1) {
  cursor: nw-resize;
}
.sizebox .sizedot:nth-of-type(2) {
  cursor: n-resize;
}
.sizebox .sizedot:nth-of-type(3) {
  cursor: ne-resize;
}
.sizebox .sizedot:nth-of-type(4) {
  cursor: e-resize;
}
.sizebox .sizedot:nth-of-type(5) {
  cursor: se-resize;
}
.sizebox .sizedot:nth-of-type(6) {
  cursor: s-resize;
}
.sizebox .sizedot:nth-of-type(7) {
  cursor: sw-resize;
}
.sizebox .sizedot:nth-of-type(8) {
  cursor: w-resize;
}
.sizebox canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.selectbox {
  position: absolute;
  display: none;
  z-index: 4;
  pointer-events: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 255, 0.25);*/
}
.selectbox .border {
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  display: none;
}
.selectbox .border svg {
  width: 100%;
  height: 100%;
  display: none;
}
.selectbox .border svg .white,
.selectbox .border svg .ants {
  stroke-width: 2px;
}
.selectbox .border.active {
  display: block;
}
.selectbox .border.active svg {
  display: block;
}
.selectbox .border.filled {
  display: block;
  background-color: rgba(255, 0, 0, 0.25);
}
.selectbox svg .white {
  fill: none;
  stroke: #FFF;
  stroke-width: 1px;
  vector-effect: non-scaling-stroke;
}
.selectbox svg .filled {
  fill: rgba(255, 0, 0, 0.25);
}
.selectbox svg .ants {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 4px;
  animation: stroke 0.2s linear infinite;
  /*shape-rendering: geometricPrecision;*/
  stroke-dashoffset: 8px;
}
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
.selectbox .content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.selectbox .content .dots {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
}
.selectbox .content .shape {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
}
.selectbox .content .shape svg {
  width: 100%;
}
.selectbox .content canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 9;
}
.selectbox.active {
  display: block;
}
.selectbox.capture {
  /*background-color: rgba(0, 255, 0, 0.15);*/
  pointer-events: all;
}

.sizedot {
  position: absolute;
  z-index: 2;
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
  border: 1px solid white;
  background-color: black;
}
.sizedot:hover {
  background-color: white;
}

.rotatedot {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin: -20px 0 0 -20px;
  cursor: url("../_img/cursors/rotatenw.png") 0 0, auto;
  display: none;
}
.rotatedot.d2 {
  margin: -20px -20px 0 0;
  cursor: url("../_img/cursors/rotatene.png") 0 0, auto;
}
.rotatedot.d3 {
  margin: 0 -20px -20px 0;
  cursor: url("../_img/cursors/rotatese.png") 0 0, auto;
}
.rotatedot.d4 {
  margin: 0 0 -20px -20px;
  cursor: url("../_img/cursors/rotatesw.png") 0 0, auto;
}
.rotatedot:hover {
  /*background-color: green;*/
}

.sizebox.canrotate .rotatedot {
  display: block;
}

.cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  margin-top: -18px;
  margin-left: -2px;
  pointer-events: none;
  background-size: contain;
  z-index: 1002;
  display: none;
}
.cursor.rotate {
  background-image: url("../_img/rotate1.svg");
  margin-top: -10px;
  margin-left: -10px;
}

body.customcursor.hoverviewport {
  cursor: none;
}
body.customcursor.hoverviewport .sizebox {
  cursor: none;
}
body.customcursor.hoverviewport .cursor {
  display: block;
}

body.hoverviewport.cursor-draw {
  cursor: url("../_img/cursors/cross.png") 12 12, auto;
}
body.hoverviewport.cursor-colorpicker {
  cursor: url("../_img/cursors/pipette.png") 0 24, auto;
}
body.hoverviewport.cursor-colorpicker canvas.overlaycanvas {
  display: none;
}
body.hoverviewport.cursor-rotate {
  cursor: url("../_img/cursors/rotatene.png") 0 0, auto;
}
body.hoverviewport.cursor-select {
  cursor: crosshair;
}
body.hoverviewport.cursor-pan {
  cursor: grab;
}
body.hoverviewport.cursor-pan canvas.overlaycanvas {
  display: none;
}

/* cursors that also apply to main UI */
body.cursor-drag,
body.hoverviewport.cursor-drag {
  cursor: grabbing !important;
}
body.cursor-drag .cursor,
body.hoverviewport.cursor-drag .cursor {
  display: none;
}

body.hovercanvas.pointerdown.cursor-colorpicker .cursor {
  display: block;
}
body.hovercanvas.pointerdown.cursor-colorpicker .cursor .mark {
  position: absolute;
  pointer-events: none;
  display: none;
  width: 60px;
  height: 60px;
  margin: -14px 0 0 -26px;
  border: 8px solid green;
  border-radius: 50%;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.5), inset 0 0 3px 1px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

#dragelement {
  position: absolute;
  z-index: 1002;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
}
#dragelement.active {
  opacity: 1;
}
#dragelement .dragelement.box {
  width: 120px;
  padding: 4px 8px;
  color: #BBBBBB;
  background-color: #313335;
  border: 1px solid #000000;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.63);
}
#dragelement .dragelement.box.frame {
  width: 50px;
  height: 50px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#dragelement .dragelement.tooltip {
  background-color: #d0c5b1;
  padding: 4px 8px;
  border: 1px solid black;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  margin: 12px 0 0 12px;
}

.blanket {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: none;
}
.blanket.active {
  display: block;
}

.modalwindow {
  position: absolute;
  z-index: 1001;
  background-color: #313335;
  width: 440px;
  height: 260px;
  border: 1px solid black;
  top: calc(50vh - 130px);
  left: 50%;
  margin-left: -100px;
  box-shadow: 0 0 8px 0px black;
  display: none;
}
.modalwindow .caption {
  color: #BBBBBB;
  padding: 2px 4px;
  border-bottom: 1px solid black;
  position: relative;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2196078431);
  cursor: move;
  user-select: none;
}
.modalwindow .caption .button {
  position: absolute;
  width: 16px;
  height: 16px;
  line-height: 14px;
  text-align: center;
  border: 1px solid black;
  top: 2px;
  right: 2px;
  font-size: 12px;
  user-select: none;
}
.modalwindow .caption .button:hover {
  background-color: #3C3F41;
  cursor: pointer;
}
.modalwindow.active {
  display: block;
}
.modalwindow .inner {
  color: #BBBBBB;
}
.modalwindow .inner .button {
  border: 1px solid #6a6a6a;
  margin: 2px;
  padding: 6px;
  user-select: none;
}
.modalwindow .inner .button .title {
  color: #BBBBBB;
}
.modalwindow .inner .button .subtitle {
  color: #797979;
}
.modalwindow .inner .button:hover {
  cursor: pointer;
  border: 1px solid #8c8c8c;
  background-color: #232323;
}
.modalwindow .inner .button.large {
  padding: 10px;
  margin: 10px;
  text-align: center;
}
.modalwindow .inner .button.highlight {
  border: 1px solid #ded293;
  color: #ded293;
}
.modalwindow .inner h3 {
  font-size: 13px;
  padding: 0 10px;
  margin: 10px 0;
}
.modalwindow .inner .panel.form {
  padding: 0 10px;
}
.modalwindow .inner .panel.form span.label {
  display: inline-block;
  width: 80px;
  text-align: right;
  padding-right: 8px;
}
.modalwindow .inner .panel.form input[type=number] {
  width: 100px;
  margin-right: 8px;
  margin-bottom: 4px;
}
.modalwindow .inner select {
  color: #BBBBBB;
  width: 100%;
  border: 1px solid black;
  background-color: transparent;
  margin: 4px 0;
}
.modalwindow .inner select:focus {
  outline: none;
}
.modalwindow .inner select option {
  color: #BBBBBB;
  background-color: #2B2B2B;
  border-radius: 0;
}
.modalwindow .inner select.resize {
  width: 100px;
  padding: 2px;
  position: absolute;
  left: 198px;
  top: 120px;
}
.modalwindow .inner .buttons {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  padding: 10px 8px;
}
.modalwindow .inner .buttons.relative {
  position: relative;
}
.modalwindow .inner .buttons .button {
  width: 150px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  margin: 0 2px;
}
.modalwindow .inner .buttons .button.ghost {
  opacity: 0.7;
}
.modalwindow .inner .about img {
  width: 100%;
  height: auto;
}
.modalwindow .inner .about .text {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  line-height: 14px;
  left: 56px;
  color: #a5bac2;
  opacity: 0.8;
  pointer-events: none;
}
.modalwindow .inner .about .text.info {
  width: 380px;
  bottom: 80px;
}
.modalwindow .inner .about .text.copyright {
  bottom: 50px;
}
.modalwindow .inner .about .text.github {
  bottom: 24px;
}
.modalwindow .inner .about .text.link {
  pointer-events: all;
}
.modalwindow .inner .about .text.link:hover {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}
.modalwindow .inner .about .text.version {
  left: 556px;
  bottom: 24px;
}
.modalwindow .inner .optiondialog p {
  margin: 0;
  padding: 10px;
}
.modalwindow .inner .lock {
  position: absolute;
  height: 33px;
  width: 13px;
  border: 1px solid transparent;
  border-left: none;
  top: 67px;
  left: 246px;
  opacity: 0.7;
}
.modalwindow .inner .lock .link {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: -8px;
  margin-top: -8px;
  background-color: #313335;
  background-image: url("../_img/link.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.modalwindow .inner .lock .link:hover {
  opacity: 1;
  cursor: pointer;
}
.modalwindow .inner .lock.active {
  border: 1px solid #8f8f8f;
  border-left: none;
  opacity: 1;
}
.modalwindow .inner .anchor {
  position: absolute;
  border: 1px solid black;
  left: 290px;
  top: 56px;
  width: 92px;
  height: 92px;
  background-color: #533030;
  display: flex;
  flex-wrap: wrap;
}
.modalwindow .inner .anchor h3 {
  position: absolute;
  left: -11px;
  top: -37px;
}
.modalwindow .inner .anchor .page {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 1px solid black;
  background-color: #47494d;
  left: 15px;
  top: 15px;
  z-index: 1;
  transition: left 0.2s ease-in-out, top 0.2s ease-in-out;
}
.modalwindow .inner .anchor .hotspot {
  width: 30px;
  height: 30px;
  position: relative;
  z-index: 2;
}
.modalwindow .inner .anchor .hotspot:hover {
  background-color: rgba(255, 255, 255, 0.13);
  cursor: pointer;
}
.modalwindow .inner .anchor .arrow {
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url("../_img/caret.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: -8px 0 0 -8px;
  opacity: 0.6;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}
.modalwindow .inner .anchor .arrow.top {
  left: 50%;
  top: 8px;
  transform: rotate(270deg);
}
.modalwindow .inner .anchor .arrow.right {
  right: 0;
  top: 50%;
}
.modalwindow .inner .anchor .arrow.bottom {
  left: 50%;
  bottom: 0;
  transform: rotate(90deg);
}
.modalwindow .inner .anchor .arrow.left {
  left: 8px;
  top: 50%;
  transform: rotate(180deg);
}
.modalwindow .inner .anchor.top .page {
  top: -1px;
}
.modalwindow .inner .anchor.top .arrow.bottom {
  bottom: 10px;
}
.modalwindow .inner .anchor.left .page {
  left: -1px;
}
.modalwindow .inner .anchor.left .arrow.right {
  right: 10px;
}
.modalwindow .inner .anchor.right .page {
  left: 31px;
}
.modalwindow .inner .anchor.right .arrow.left {
  left: 18px;
}
.modalwindow .inner .anchor.bottom .page {
  top: 31px;
}
.modalwindow .inner .anchor.bottom .arrow.top {
  top: 18px;
}
.modalwindow .inner .quick {
  position: absolute;
  top: 123px;
  left: 88px;
  font-size: 12px;
}
.modalwindow .inner .quick .button.calc {
  display: inline-block;
  width: 22px;
  padding: 2px;
  text-align: center;
}
.modalwindow .inner .textlink {
  text-decoration: underline;
  color: #BBBBBB;
}
.modalwindow .inner .textlink:hover {
  color: white;
  cursor: pointer;
}
.modalwindow .inner.full {
  position: absolute;
  left: 0;
  top: 20px;
  right: 0;
  bottom: 0;
}
.modalwindow .inner .palette {
  position: relative;
  height: 281px;
  margin-top: 1px;
}
.modalwindow .inner .palette.withactions .palettepanel .caption:after {
  transform: rotate(180deg);
}
.modalwindow .inner .palette.withactions .mainpanel {
  left: 201px;
}
.modalwindow .inner .palette.withactions .mainpanel .sliders {
  padding-left: 10px;
  padding-right: 10px;
}
.modalwindow .inner .palette.withactions .mainpanel .sliders input.hex {
  right: 10px;
  width: 80px;
}
.modalwindow .inner .palette.withactions .mainpanel .sliders .pixelcount {
  left: 75px;
}
.modalwindow .inner .palette.withactions .mainpanel .sliders .slider input.slider {
  width: 110px;
  margin: 4px 0 0 8px;
}
.modalwindow .inner .palette.withactions .mainpanel .sliders .slider span.label {
  right: 43px;
  font-size: 12px;
  width: 40px;
}
.modalwindow .inner .palette.withactions .mainpanel .buttons {
  right: 0;
}
.modalwindow .inner .palette.withactions .mainpanel .options {
  padding-left: 10px;
}
.modalwindow .inner .palette.withactions .actions {
  display: block;
}
.modalwindow .inner .palette .caption.sub {
  cursor: default;
  font-size: 12px;
  line-height: 17px;
}
.modalwindow .inner .palette .palettepanel {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 121px;
  border-right: 1px solid rgba(0, 0, 0, 0.58);
  background-color: #292b2c;
}
.modalwindow .inner .palette .palettepanel .caption:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 18px;
  right: 2px;
  background-image: url("../_img/caret.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.5;
  transition: transform 0.3s ease-in-out;
}
.modalwindow .inner .palette .palettepanel .caption:hover {
  cursor: pointer;
}
.modalwindow .inner .palette .palettepanel .caption:hover:after {
  opacity: 1;
}
.modalwindow .inner .palette .palettepanel canvas {
  display: block;
  margin: 0;
}
.modalwindow .inner .palette .palettepanel .highlight {
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 21px 0 0 1px;
  border: 3px solid black;
  pointer-events: none;
}
.modalwindow .inner .palette .palettepanel .highlight:before {
  content: "";
  position: absolute;
  right: -1px;
  bottom: -1px;
  left: -4px;
  top: -4px;
  border: 2px solid white;
}
.modalwindow .inner .palette .palettepanel .nav {
  border-top: 1px solid black;
  width: 120px;
  height: 20px;
  background-color: #313335;
  display: flex;
  color: #BBBBBB;
}
.modalwindow .inner .palette .palettepanel .nav .prev, .modalwindow .inner .palette .palettepanel .nav .next {
  width: 40px;
  background-image: url(../_img/caret.svg);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  pointer-events: none;
}
.modalwindow .inner .palette .palettepanel .nav .prev.active, .modalwindow .inner .palette .palettepanel .nav .next.active {
  opacity: 0.5;
  pointer-events: all;
}
.modalwindow .inner .palette .palettepanel .nav .prev:hover, .modalwindow .inner .palette .palettepanel .nav .next:hover {
  opacity: 1;
  cursor: pointer;
}
.modalwindow .inner .palette .palettepanel .nav .prev {
  transform: rotate(180deg);
}
.modalwindow .inner .palette .palettepanel .nav .page {
  width: 40px;
  text-align: center;
}
.modalwindow .inner .palette .actions {
  position: absolute;
  left: 121px;
  width: 80px;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.58);
  display: none;
}
.modalwindow .inner .palette .actions .caption:after {
  content: "x";
  text-align: center;
  position: absolute;
  width: 16px;
  height: 18px;
  right: 2px;
  opacity: 0.5;
  transition: transform 0.3s ease-in-out;
}
.modalwindow .inner .palette .actions .caption:hover {
  cursor: pointer;
}
.modalwindow .inner .palette .actions .caption:hover:after {
  opacity: 1;
}
.modalwindow .inner .palette .actions .spacer {
  height: 20px;
}
.modalwindow .inner .palette .actions .nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  border-top: 1px solid black;
}
.modalwindow .inner .palette .actions .nav div {
  width: 100%;
  height: 20px;
  background-image: url("../_img/zoom.svg");
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.5;
}
.modalwindow .inner .palette .actions .nav div:hover {
  cursor: pointer;
  opacity: 1;
}
.modalwindow .inner .palette .actions .nav div.zoomout {
  background-image: url("../_img/zoomout.svg");
  border-left: 1px solid black;
}
.modalwindow .inner .palette .mainpanel {
  position: absolute;
  left: 121px;
  right: 0;
  top: 0;
  bottom: 0;
}
.modalwindow .inner .palette .mainpanel .tabs {
  display: flex;
  width: 100%;
}
.modalwindow .inner .palette .mainpanel .tabs .caption.sub {
  width: 100%;
  padding-left: 10px;
  pointer-events: none;
}
.modalwindow .inner .palette .mainpanel .tabs .caption.sub.inactive {
  background-color: #313335;
  opacity: 0.5;
  pointer-events: all;
}
.modalwindow .inner .palette .mainpanel .tabs .caption.sub.inactive:hover {
  cursor: pointer;
  opacity: 1;
}
.modalwindow .inner .palette .mainpanel .colorpanel {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
}
.modalwindow .inner .palette .mainpanel .rangepanel {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  display: none;
}
.modalwindow .inner .palette .mainpanel .rangepanel .norange {
  padding: 20px;
  opacity: 0.5;
}
.modalwindow .inner .palette .mainpanel .rangepanel .bottom {
  position: absolute;
  bottom: 0;
  padding: 4px;
  display: flex;
}
.modalwindow .inner .palette .mainpanel .rangepanel .bottom .button.small {
  position: relative;
  width: auto;
}
.modalwindow .inner .palette .mainpanel .rangepanel .bottom .button.small.active {
  border: 1px solid #b1e00f;
  color: #b1e00f;
  box-shadow: 0 0 2px yellow;
}
.modalwindow .inner .palette .mainpanel .rangepanel .captions {
  display: flex;
  font-size: 11px;
  border-bottom: 1px solid black;
  background-color: #262829;
  color: #808080;
}
.modalwindow .inner .palette .mainpanel .rangepanel .captions div {
  padding: 4px;
  border-left: 1px solid #313335;
}
.modalwindow .inner .palette .mainpanel .rangepanel .captions div.a {
  padding: 4px 2px;
  border-left: none;
}
.modalwindow .inner .palette .mainpanel .rangepanel .captions div.b {
  width: 100px;
}
.modalwindow .inner .palette .mainpanel .rangepanel .ranges {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 30px;
  overflow: auto;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range {
  padding: 4px 4px 4px 8px;
  overflow: auto;
  overflow-y: hidden;
  border-bottom: 1px solid black;
  border-top: 1px solid #464545;
  display: flex;
  align-items: center;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range.active {
  background-color: #3e4144;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range .checkbox {
  display: block;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range .slider {
  display: block;
  width: 60px;
  margin: 0 4px;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range .speed {
  width: 40px;
  margin: 0 4px 0 0;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range canvas {
  display: block;
  cursor: pointer;
}
.modalwindow .inner .palette .mainpanel .rangepanel .range canvas.active {
  box-shadow: 0 0 5px 2px #b1e00f;
}
.modalwindow .inner .palette .mainpanel .sliders {
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  padding: 0 20px;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs {
  display: block;
  margin-top: 10px;
  position: relative;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab {
  position: absolute;
  left: 0;
  font-size: 11px;
  height: 50px;
  width: 18px;
  text-align: center;
  top: 53px;
  background-color: #313335;
  pointer-events: none;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:before, .modalwindow .inner .palette .mainpanel .sliders .tabs .tab:after {
  content: "";
  position: absolute;
  left: 0;
  right: 1px;
  border-top: 1px solid #6A6A6A;
  transform-origin: top right;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:before {
  top: 0;
  transform: rotate(-15deg);
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:after {
  bottom: -1px;
  transform: rotate(15deg);
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab span {
  display: block;
  border-left: 1px solid #6A6A6A;
  margin: 5px 0;
  width: 14px;
  padding: 2px 0 2px 4px;
  height: 41px;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:first-child {
  top: 0;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab:first-child:before {
  right: 0;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab.inactive {
  opacity: 0.4;
  margin-left: 1px;
  transition: all 0.1s ease-in-out;
  pointer-events: all;
  background-color: transparent;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .tab.inactive:hover {
  cursor: pointer;
  opacity: 1;
  margin-left: -1px;
}
.modalwindow .inner .palette .mainpanel .sliders .tabs .panel {
  border: 1px solid #6A6A6A;
  margin-left: 16px;
}
.modalwindow .inner .palette .mainpanel .sliders .slider {
  position: relative;
  margin: 8px 0;
}
.modalwindow .inner .palette .mainpanel .sliders .slider span.label {
  position: absolute;
  right: 49px;
  text-align: left;
  text-transform: capitalize;
  font-size: 13px;
  margin-top: 5px;
  width: 45px;
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider {
  width: 148px;
  margin: 4px 0 0 12px;
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.red::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000000 0%, #c40202 100%);
  border: none;
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.red.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.green::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000000 0%, #00a402 100%);
  border: none;
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.green.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000000 0%, #c40202 100%);
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.blue::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000000 0%, #085db9 100%);
  border: none;
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.slider.blue.hsv::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #000000 0%, #FFFFFF 100%);
}
.modalwindow .inner .palette .mainpanel .sliders .slider input.rangevalue {
  position: absolute;
  right: 4px;
  width: 40px;
}
.modalwindow .inner .palette .mainpanel .sliders input.hex {
  width: 100px;
  position: absolute;
  right: 20px;
}
.modalwindow .inner .palette .mainpanel .sliders input.masked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 0;
  top: 0;
  height: 30px;
}
.modalwindow .inner .palette .mainpanel .sliders .pixelcount {
  position: absolute;
  left: 86px;
  top: 1px;
  font-size: 12px;
}
.modalwindow .inner .palette .mainpanel .button.small {
  position: absolute;
  width: 50px;
}
.modalwindow .inner .palette .mainpanel .button.small.revert {
  right: 60px;
}
.modalwindow .inner .palette .mainpanel .button.small.apply {
  right: 8px;
}
.modalwindow .inner .palette .buttons {
  position: absolute;
  left: 0;
  bottom: 72px;
  right: 10px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.modalwindow .inner .palette .buttons.active {
  opacity: 1;
  pointer-events: all;
}
.modalwindow .inner .palette .button.small {
  line-height: 14px;
  font-size: 12px;
  padding: 2px 4px;
  text-align: center;
}
.modalwindow .inner .palette .button.small .contextmenu {
  position: absolute;
}
.modalwindow .inner .palette .button.small .contextmenu .item {
  padding: 2px 4px;
  background-color: #313335;
  border: 1px solid black;
  border-radius: 2px;
  font-size: 12px;
  margin: 2px 0;
  width: 100px;
  text-align: left;
}
.modalwindow .inner .palette .button.small .contextmenu .item:hover {
  cursor: pointer;
  background-color: #3C3F41;
}
.modalwindow .inner .palette .options {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 5px 10px 5px 20px;
  border-top: 1px solid black;
}
.modalwindow .inner .palette .options .checkbox {
  padding: 3px 0;
  position: relative;
  font-size: 12px;
}
.modalwindow .inner .effects {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.modalwindow .inner .effects .tabs {
  position: absolute;
  left: 5px;
  width: 220px;
  border-bottom: 1px solid black;
  height: 36px;
  overflow: hidden;
}
.modalwindow .inner .effects .tabs .tab {
  position: absolute;
  width: 90px;
  text-align: center;
  line-height: 24px;
  border: 1px solid black;
  height: 24px;
  bottom: -3px;
  left: 5px;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  user-select: none;
}
.modalwindow .inner .effects .tabs .tab:nth-of-type(2) {
  left: 100px;
}
.modalwindow .inner .effects .tabs .tab:hover, .modalwindow .inner .effects .tabs .tab.active {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  bottom: 0;
  opacity: 1;
}
.modalwindow .inner .effects .sliders {
  position: absolute;
  left: 10px;
  top: 40px;
  display: none;
}
.modalwindow .inner .effects .sliders.active {
  display: block;
}
.modalwindow .inner .effects .slider {
  position: relative;
  margin: 4px 0;
}
.modalwindow .inner .effects .slider label {
  display: block;
  font-size: 12px;
  text-transform: capitalize;
}
.modalwindow .inner .effects .slider input[type=range] {
  width: 200px;
  position: relative;
  z-index: 10;
  margin-top: -2px;
}
.modalwindow .inner .effects .slider input[type=range]::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(200, 200, 200) 100%);
  border: 1px solid #010101;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
.modalwindow .inner .effects .slider input[type=range]::-moz-range-track {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(200, 200, 200) 100%);
  border: 1px solid #010101;
  width: 100%;
  height: 2px;
  cursor: pointer;
}
.modalwindow .inner .effects .slider input[type=range].saturation::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #7F7F7F 0%, #F50A0A 100%);
}
.modalwindow .inner .effects .slider input[type=range].saturation::-moz-range-track {
  background: linear-gradient(90deg, #7F7F7F 0%, #F50A0A 100%);
}
.modalwindow .inner .effects .slider input[type=range].contrast::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #7F7F7F 0%, #000000 100%);
}
.modalwindow .inner .effects .slider input[type=range].contrast::-moz-range-track {
  background: linear-gradient(90deg, #7F7F7F 0%, #000000 100%);
}
.modalwindow .inner .effects .slider input[type=range].hue::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
}
.modalwindow .inner .effects .slider input[type=range].hue::-moz-range-track {
  background: linear-gradient(90deg, #FF3A00 0%, #F0FF00 20%, #00FF1D 40%, #0007FF 60%, #FF00F1 80%, #FA0505 100%);
}
.modalwindow .inner .effects .slider input[type=range].sepia::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #313335 0%, #8A4F38 100%);
}
.modalwindow .inner .effects .slider input[type=range].sepia::-moz-range-track {
  background: linear-gradient(90deg, #313335 0%, #8A4F38 100%);
}
.modalwindow .inner .effects .slider input[type=range].cyan::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #0AF5F5 0%, #F50A0A 100%);
}
.modalwindow .inner .effects .slider input[type=range].cyan::-moz-range-track {
  background: linear-gradient(90deg, #0AF5F5 0%, #F50A0A 100%);
}
.modalwindow .inner .effects .slider input[type=range].magenta::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #F50AF5 0%, #0AF50A 100%);
}
.modalwindow .inner .effects .slider input[type=range].magenta::-moz-range-track {
  background: linear-gradient(90deg, #F50AF5 0%, #0AF50A 100%);
}
.modalwindow .inner .effects .slider input[type=range].yellow::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, #F5F50A 0%, #0A0AF5 100%);
}
.modalwindow .inner .effects .slider input[type=range].yellow::-moz-range-track {
  background: linear-gradient(90deg, #F5F50A 0%, #0A0AF5 100%);
}
.modalwindow .inner .effects .slider input[type=text] {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 2px 4px;
}
.modalwindow .inner .effects .alchemy {
  position: absolute;
  left: 10px;
  top: 40px;
  width: 200px;
  display: none;
}
.modalwindow .inner .effects .alchemy.active {
  display: block;
}
.modalwindow .inner .effects .alchemy .recipe {
  padding: 6px 6px 6px 24px;
  border-bottom: 1px solid black;
  background-image: url("../_img/flask.svg");
  background-repeat: no-repeat;
  background-position: 2px center;
  background-size: 16px 16px;
  font-size: 12px;
}
.modalwindow .inner .effects .alchemy .recipe:hover {
  cursor: pointer;
  background-color: #3C3F41;
}
.modalwindow .inner .effects .code {
  position: absolute;
  left: 10px;
  top: 250px;
  right: 10px;
}
.modalwindow .inner .effects .code code {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 170px;
  overflow: auto;
  white-space: pre;
  font-family: monospace;
  display: block;
  border: 1px solid black;
  background-color: #1c1c1c;
  color: #8f8f8f;
}
.modalwindow .inner .effects .code code:focus {
  outline: none;
}
.modalwindow .inner .effects .code code .string {
  color: #A1E46D;
}
.modalwindow .inner .effects .code code .number {
  color: #4176BA;
}
.modalwindow .inner .effects .code code .html {
  color: #E4D95F;
}
.modalwindow .inner .effects .code code .reserved {
  color: #C87531;
}
.modalwindow .inner .effects .code code .js {
  color: #6DE4D1;
}
.modalwindow .inner .effects .code code .globals {
  color: #ab66b7;
}
.modalwindow .inner .effects .code code .comment {
  color: #aaa;
}
.modalwindow .inner .effects .code code .method {
  color: #F9C26B;
}
.modalwindow .inner .effects .code code .source {
  color: #64b969;
}
.modalwindow .inner .effects .code code .target {
  color: #64b9a9;
}
.modalwindow .inner .effects .code .button {
  position: absolute;
  left: 0;
  top: 176px;
  user-select: none;
}
.modalwindow .inner .effects .code .params {
  position: absolute;
  background-color: #313335;
  z-index: 1;
  right: 0;
  top: -242px;
  width: 256px;
  height: 206px;
}
.modalwindow .inner .effects .code .params h3 {
  padding-left: 0;
}
.modalwindow .inner .effects .code .params .slider {
  margin-left: 20px;
  margin-right: 36px;
}
.modalwindow .inner .effects .code .params .slider label,
.modalwindow .inner .effects .code .params .slider input[type=text] {
  font-size: 11px;
}
.modalwindow .inner .effects .code .params .slider input[type=text] {
  padding: 1px 2px;
}
.modalwindow .inner .effects .code .params .slider input[type=range]::-webkit-slider-thumb {
  height: 12px;
  margin-top: -5px;
}
.modalwindow .inner .effects .code .params .slider input[type=range]::-ms-thumb {
  height: 12px;
}
.modalwindow .inner .effects .code .params .slider input[type=range]::-moz-range-thumb {
  height: 10px;
}
.modalwindow .inner .effects .code .params.columns .slider {
  margin-left: 10px;
  margin-right: 0;
  width: 110px;
  display: inline-block;
}
.modalwindow .inner .effects .code .params.columns .slider.main {
  width: 230px;
}
.modalwindow .inner .effects .code .params.columns .slider.main input[type=range] {
  width: 230px;
}
.modalwindow .inner .effects .code .params.columns .slider input[type=range] {
  width: 110px;
}
.modalwindow .inner .effects .code .params.columns .slider input[type=text] {
  width: 30px;
}
.modalwindow .inner .effects .previewpanel {
  position: absolute;
  left: 240px;
  top: 12px;
}
.modalwindow .inner .effects .previewpanel .preview {
  width: 200px;
  height: 200px;
  border: 1px solid black;
  margin-bottom: 10px;
}
.modalwindow .inner .effects .buttons {
  left: 0;
  right: 0;
  justify-content: flex-end;
}
.modalwindow .inner .effects .buttons .button.left {
  position: absolute;
  left: 10px;
}
.modalwindow .inner .ditheredit .preset {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid black;
  background-size: 50% 50%;
  background-color: #6b6b6b;
  image-rendering: optimizeSpeed;
  image-rendering: optimize-contrast;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
.modalwindow .inner .ditheredit .preset:hover {
  border: 1px solid #b1e00f;
  background-color: #bbbbbb;
  cursor: pointer;
}
.modalwindow .inner .ditheredit .preset.p0 {
  background-image: url("../_img/patterns/dots.png");
}
.modalwindow .inner .ditheredit .preset.p1 {
  background-image: url("../_img/patterns/cross.png");
}
.modalwindow .inner .ditheredit .preset.p2 {
  background-image: url("../_img/patterns/grid.png");
}
.modalwindow .inner .ditheredit .preset.p3 {
  background-image: url("../_img/patterns/cross2.png");
}
.modalwindow .inner .ditheredit .preset.p4 {
  background-image: url("../_img/patterns/lines_hor.png");
}
.modalwindow .inner .ditheredit .preset.p5 {
  background-image: url("../_img/patterns/lines_ver.png");
}
.modalwindow .inner .ditheredit .preset.p6 {
  background-image: url("../_img/patterns/lines_diag.png");
}
.modalwindow .inner .ditheredit .preset.p7 {
  background-image: url("../_img/patterns/longgrid.png");
}
.modalwindow .inner .ditheredit .preset.user canvas {
  width: 100%;
  height: 100%;
}
.modalwindow .inner .ditheredit .editpreset,
.modalwindow .inner .ditheredit .previewpreset,
.modalwindow .inner .ditheredit .presets {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 202px;
  border: 1px solid black;
}
.modalwindow .inner .ditheredit .editpreset {
  left: 210px;
  width: 242px;
}
.modalwindow .inner .ditheredit .previewpreset {
  left: 455px;
}
.modalwindow .inner .ditheredit .previewpreset canvas {
  background-image: url("../_img/patterns/gradient.png");
  background-size: 100% 100%;
}
.modalwindow .inner .ditheredit h2 {
  font-size: 12px;
  padding: 4px;
  border-bottom: 1px solid black;
  font-weight: normal;
  margin: 0;
}
.modalwindow .inner .ditheredit .subtoolbar {
  border-top: 1px solid black;
  text-align: right;
  font-size: 12px;
  position: relative;
  padding: 0;
}
.modalwindow .inner .ditheredit .subtoolbar .button {
  display: inline-block;
  padding: 4px 9px;
  text-align: center;
  white-space: nowrap;
}
.modalwindow .inner .ditheredit .subtoolbar .button.small {
  width: 24px;
  padding: 4px;
}
.modalwindow .inner .ditheredit .subtoolbar .button.active {
  border-color: #b2970e;
  color: #b2970e;
  background-color: #413e28;
}
.modalwindow .inner .ditheredit .subtoolbar .button.hidden {
  display: none;
}
.modalwindow .inner .ditheredit .subtoolbar .button.left {
  position: absolute;
  left: 0;
  max-width: 49%;
}
.modalwindow .inner .saveform {
  padding: 10px;
}
.modalwindow .inner .saveform h4 {
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: normal;
}
.modalwindow .inner .saveform h4.amiga, .modalwindow .inner .saveform h4.general {
  background-image: url("../_img/amigatick.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 20px;
}
.modalwindow .inner .saveform h4.general {
  background-image: url("../_img/image.svg");
  padding-left: 22px;
}
.modalwindow .inner .saveform input {
  width: 100%;
  margin-bottom: 10px;
}
.modalwindow .inner .saveform .platform {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.modalwindow .inner .saveform .platform .button {
  border: none;
  position: relative;
  padding: 5px 5px 5px 40px;
}
.modalwindow .inner .saveform .platform .button .icon {
  width: 32px;
  height: 32px;
  left: 0;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.modalwindow .inner .saveform .platform .button .icon.png {
  background-image: url("../_img/png.svg");
}
.modalwindow .inner .saveform .platform .button .icon.json {
  background-image: url("../_img/json.svg");
}
.modalwindow .inner .saveform .platform .button .icon.psd {
  background-image: url("../_img/psd.svg");
}
.modalwindow .inner .saveform .platform .button .icon.mui {
  background-image: url("../_img/mui.png");
}
.modalwindow .inner .saveform .platform .button .icon.os3 {
  background-image: url("../_img/os3.png");
}
.modalwindow .inner .saveform .platform .button .icon.os4 {
  background-image: url("../_img/os4.png");
}
.modalwindow .inner .saveform .platform .button .icon.iff {
  background-image: url("../_img/iff.png");
}
.modalwindow .inner .saveform .platform .button .icon.adf {
  background-image: url("../_img/floppy.png");
}
.modalwindow .inner .saveform .platform .button .info {
  background-color: #4d5052;
  position: absolute;
  padding: 4px 4px 4px 8px;
  border: 1px solid black;
  top: 0;
  left: 140px;
  z-index: 10;
  width: 200px;
  opacity: 0;
  pointer-events: none;
  min-height: 40px;
  transition: opacity 0.2s ease-in-out, left 0.2s ease-in-out;
}
.modalwindow .inner .saveform .platform .button .info:before {
  content: "";
  position: absolute;
  top: 10px;
  left: -21px;
  border: 10px solid transparent;
  border-right: 10px solid black;
}
.modalwindow .inner .saveform .platform .button .info:after {
  content: "";
  position: absolute;
  top: 10px;
  left: -20px;
  border: 10px solid transparent;
  border-right: 10px solid #4d5052;
}
.modalwindow .inner .saveform .platform .button:hover .info {
  opacity: 1;
  left: 160px;
}
.modalwindow .inner .saveform .platform .button.more {
  position: absolute;
  bottom: 10px;
  min-width: 60px;
  padding: 5px 8px;
}
.modalwindow .inner .saveform .platform .button.more:after {
  content: "";
  position: absolute;
  right: 2px;
  width: 16px;
  height: 17px;
  background-image: url("../_img/caret.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}
.modalwindow .inner .saveform .platform.amiga .button .info {
  left: unset;
  right: 140px;
  transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
}
.modalwindow .inner .saveform .platform.amiga .button .info:before {
  left: unset;
  right: -21px;
  transform: rotate(180deg);
}
.modalwindow .inner .saveform .platform.amiga .button .info:after {
  left: unset;
  right: -20px;
  transform: rotate(180deg);
}
.modalwindow .inner .saveform .platform.amiga .button:hover .info {
  right: 160px;
}
.modalwindow .inner .saveform .moremenu {
  z-index: 10;
  position: absolute;
  top: 20px;
  bottom: 0;
  right: 0;
  width: 220px;
  background-color: #2B2B2B;
  border-left: 1px solid black;
  display: none;
}
.modalwindow .inner .saveform .moremenu .item {
  padding: 5px 5px 5px 44px;
  border-bottom: 1px solid black;
  position: relative;
}
.modalwindow .inner .saveform .moremenu .item .subtitle {
  font-size: 13px;
  padding: 4px 0;
  color: #797979;
}
.modalwindow .inner .saveform .moremenu .item:hover {
  background-color: #232323;
  cursor: pointer;
}
.modalwindow .inner .saveform .moremenu .item:hover .info {
  opacity: 1;
  right: 220px;
}
.modalwindow .inner .saveform .moremenu .item:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 34px;
  height: 34px;
  background-image: url("../_img/layers.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.modalwindow .inner .saveform .moremenu .item.mask:after {
  background-image: url("../_img/layers_mask.svg");
}
.modalwindow .inner .saveform .moremenu .item.index:after {
  background-image: url("../_img/pixelgrid.svg");
}
.modalwindow .inner .saveform .moremenu .item.loading:after {
  display: none;
}
.modalwindow .inner .saveform .moremenu .item .spinner {
  top: 8px;
  left: 30px;
  width: 32px;
  height: 32px;
}
.modalwindow .inner .saveform .moremenu .item .info {
  background-color: #232323;
  position: absolute;
  padding: 4px 4px 4px 8px;
  border: 1px solid black;
  top: 25px;
  z-index: 10;
  width: 170px;
  opacity: 0;
  pointer-events: none;
  min-height: 40px;
  transition: opacity 0.2s ease-in-out, right 0.2s ease-in-out;
  right: 120px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5098039216);
}
.modalwindow .inner .saveform .moremenu .item .info:before {
  content: "";
  position: absolute;
  top: 10px;
  border: 10px solid transparent;
  border-right: 10px solid black;
  right: -21px;
  transform: rotate(180deg);
}
.modalwindow .inner .saveform .moremenu .item .info:after {
  content: "";
  position: absolute;
  top: 10px;
  right: -20px;
  transform: rotate(180deg);
  border: 10px solid transparent;
  border-right: 10px solid #232323;
}
.modalwindow .inner .saveform.hasmore .moremenu {
  display: block;
}
.modalwindow .inner .saveform.hasmore .button.more:after {
  transform: rotate(180deg);
}
.modalwindow .inner .saveform.hasmore input {
  width: calc(100% - 220px);
}
.modalwindow .inner .saveoverlay {
  position: absolute;
  top: 20px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: #3D3F41;
  z-index: 10;
}
.modalwindow .inner .saveoverlay .info {
  text-align: center;
  padding: 50px 10px 10px 10px;
}
.modalwindow .inner .saveoverlay .info b {
  font-size: 20px;
  display: block;
  padding: 20px 0;
}
.modalwindow .inner .saveoverlay .spinner {
  display: none;
}
.modalwindow .inner .saveoverlay.loading .info {
  display: none;
}
.modalwindow .inner .saveoverlay.loading .buttons {
  pointer-events: none;
  opacity: 0.5;
}
.modalwindow .inner .saveoverlay.loading .spinner {
  display: block;
}

.notificationbox {
  position: fixed;
  left: 20px;
  bottom: 40px;
  z-index: 1000;
  width: 250px;
  height: 80px;
  background-color: #1a1a1a;
  color: #BBBBBB;
  border: 1px solid #3b3b3b;
  font-size: 13px;
  margin-left: -200px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.notificationbox .title {
  background-color: #101010;
  border-bottom: 1px solid #3b3b3b;
  padding: 4px 10px;
}
.notificationbox .text {
  padding: 4px 10px;
}
.notificationbox.active {
  display: block;
  opacity: 1;
  margin-left: 0;
  pointer-events: all;
}

.filebrowser {
  position: absolute;
  right: 0;
  top: 27px;
  width: 150px;
  bottom: 22px;
  background-color: #313335;
  border: 1px solid black;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
}
.filebrowser.active {
  display: block;
}
.filebrowser .caption {
  color: #BBBBBB;
  padding: 4px 5px 2px 20px;
  font-size: 12px;
  height: 21px;
  border-bottom: 1px solid black;
}
.filebrowser .caption .close {
  position: absolute;
  height: 20px;
  width: 20px;
  line-height: 20px;
  left: 0;
  top: 0;
  text-align: center;
  cursor: pointer;
}
.filebrowser .disk {
  padding: 2px 2px 2px 21px;
  border-bottom: 1px solid black;
  color: #BBBBBB;
  font-size: 11px;
  line-height: 20px;
  background-image: url("../_img/disk.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 2px center;
  position: relative;
}
.filebrowser .disk .download {
  position: absolute;
  height: 16px;
  width: 16px;
  right: 2px;
  top: 2px;
  cursor: pointer;
  opacity: 0.7;
  background-image: url("../_img/download.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.filebrowser .disk .download:hover {
  opacity: 1;
}
.filebrowser .listitem {
  color: #BBBBBB;
  padding: 2px 2px 2px 21px;
  border-bottom: 1px solid black;
  position: relative;
}
.filebrowser .listitem.folder {
  background-image: url("../_img/folder.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 2px center;
}
.filebrowser .listitem.file {
  opacity: 0.5;
}
.filebrowser .listitem.file:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: grey;
}
.filebrowser .listitem.file.image {
  opacity: 1;
}
.filebrowser .listitem.file.image:before {
  background-color: #71b471;
}
.filebrowser .listitem:hover {
  cursor: pointer;
  background-color: #3C3F41;
  color: white;
}

.uae {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #282A2C;
  box-shadow: 0 0 20px 0px black;
}
.uae .caption {
  height: 20px;
  color: #BBBBBB;
  border-bottom: 1px solid black;
  font-size: 12px;
  padding: 2px 2px 2px 24px;
  background-image: url("../_img/amigatick.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 2px center;
  cursor: move;
}
.uae .caption .close {
  position: absolute;
  height: 20px;
  width: 20px;
  line-height: 20px;
  right: 0;
  top: 0;
  text-align: center;
  cursor: pointer;
  opacity: 0.7;
}
.uae .caption .close:hover {
  opacity: 1;
}
.uae .resizer {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: 0;
  border: 10px solid transparent;
  border-bottom-color: #313335;
  border-right-color: #313335;
  cursor: nwse-resize;
}
.uae iframe {
  width: 100%;
  height: calc(100% - 21px);
  border: none;
}
.uae.dragging iframe {
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .menu {
    width: 100px;
    height: auto;
    top: 27px;
    border: none;
  }
  .menu .hamburger {
    display: block;
    background-image: url("../_img/hamburger.svg");
    width: 200px;
    height: 27px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: 6px center;
    position: absolute;
    top: -27px;
    padding: 6px 4px 2px 40px;
  }
  .menu a.main {
    display: none;
    font-size: 13px;
    border: 1px solid black;
    border-bottom: none;
    padding: 2px 10px;
  }
  .menu a.main:last-child {
    border-bottom: 1px solid black;
  }
  .menu a.main .sub {
    left: 100px;
    top: 0;
  }
  .menu a.main .sub a {
    font-size: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding: 2px 20px 2px 10px;
  }
  .menu a.main .sub a:last-child {
    border-bottom: none;
  }
  .menu a.main .sub a.wide {
    padding: 2px 80px 2px 10px;
  }
  .menu a.main .sub a.ultrawide {
    padding: 2px 100px 2px 10px;
  }
  .menu.active a.main {
    display: block;
  }
}

