.sidepanel{
  position: absolute;
  left: 70px;
  top: 27px;
  bottom: 20px;
  border: 1px solid black;
  width: 175px;
  background-color: $panel-background-color;
  display: none;
  overflow: auto;

  .panel{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .inner{
      position: absolute;
      top: 22px;
      bottom: 0;
      overflow: auto;
      left: 0;
      right: 0;

      small{
        font-size: 11px;
        color: $dimmed-text-color;
        text-align: center;
        margin-top: 20px;
        display: block;
      }

      dl{
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #4d4d4d;
        display: flex;
        dt,
        dd{
          display: inline-block;
          color: $dimmed-text-color;
          font-size: 11px;
          padding: 3px 5px;
          margin: 0;
          background-color: rgba(255, 255, 255, 0.05);
          width: 60px;
        }

        dd{
          opacity: 0.7;
          width: 100px;
        }
      }

      .layer{
        padding: 5px;
        color: $menu-text-color;
        border-bottom: 1px solid #626262;
        font-size: 12px;
        user-select: none;
        position: absolute;
        left: 0;
        right: 0;
        height: 23px;

        &.active{
          background-color: rgba(255, 255, 255, 0.1);
          color: white;

          .mask{
            &:after{
              background-color: #46484A;
            }
          }
        }

        &:hover{
          cursor: pointer;
        }

        &.ghost,
        &.hidden{
          opacity: 0.4;
          .eye{
            opacity: 0.4;
          }
        }

        &.locked{
          pointer-events: none;
        }

        .eye{
          position: absolute;
          right: 4px;
          top: 7px;
          width: 16px;
          height: 16px;
          background-image: url("../_img/eye.svg");
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0.7;
        }

        .lock{
          position: absolute;
          right: 24px;
          top: 3px;
          width: 16px;
          height: 16px;
          background-image: url("../_img/lock_closed.svg");
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 0.7;
        }

        .mask{
          position: absolute;
          right: 27px;
          top: 4px;
          width: 16px;
          height: 16px;
          border: 1px solid #C7C8C8;
          background-color: #C7C8C8;
          text-align: center;

          &:after{
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 14px;
            height: 14px;
            border-radius: 7px;
            margin: -7px 0 0 -7px;
            background-color: #313335;
          }

          &.active{
            border: 1px solid $active-color;
            background-color: $active-color;
            box-shadow: 0 0 2px yellow;
          }
        }

        input[type="text"]{
          position: absolute;
          z-index: 10;
          left: 0;
          top: 0;
          font-size: 12px;
          right: 0;
        }
      }

      .frame{
        width: 50px;
        height: 50px;
        border: 1px solid #626262;
        margin: 1px;
        position: absolute;
        opacity: 0.8;

        &:hover{
          border: 1px solid white;
          cursor: pointer;
          opacity: 1;
        }

        .label{
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.4);
          color: $menu-text-color-dim;
          font-size: 12px;
          padding: 4px;
        }

        &.active{
          box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.7);
          opacity: 1;

          .label{
            color: $menu-text-color;
          }
        }

        &.ghost{
          opacity: 0.2;
        }
      }

    }

    h2{
      font-size: 14px;
      color: $menu-text-color;
      font-weight: 100;
      padding: 2px;
      margin: 0;
    }

    &.color{

    }

    &.frames{
      .panelcontent{
        position: absolute;
        left: 0;
        right: 0;
        top: 22px;
        bottom: 0;
        overflow: auto;
        overflow-y: hidden;

        &.inactive{
          pointer-events: none;
        }
      }
    }

    &.collapsed{
      .caption{
        i{
          transform: rotate(0deg);
        }
      }

      .inner{
        display: none;
      }
    }

    .paneltools{
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      background-color: $button-background-medium;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: $menu-text-color;
      position: relative;

      &.multirow{
        height: 47px;
      }

      .button{
        height: 20px;
        width: 20px;
        margin-left: 2px;
        font-size: 12px;
        line-height: 20px;
        border: none;
        border-right: 1px solid black;
        background-color: #282A2C;
        background-image: url("../_img/add.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;

        &.delete{
          background-image: url("../_img/trashcan.svg");
        }

        &:hover{
          cursor: pointer;
          opacity: 1;
        }
      }

      .rangeselect{
        position: absolute;
        left: 4px;
        top: 4px;
        right: 44px;
        font-size: 11px;
        color: #9d9c9c;

        input{
          position: absolute;
          margin: 0 0 0 4px;
          height: 17px;
          left: 40px;
          top: 0;
          right: 0;
          width: auto;

          &::-webkit-slider-thumb,
          &::-moz-range-thumb{
            margin-top: -6px;
            width: 8px;
            height: 12px;
            background: #9d9d9d;
            border: 1px solid rgba(50, 50, 50, 0.7);
          }
          &::-moz-range-thumb{
            width: 6px;
            height: 10px;
          }
        }
      }

      .blendselect{
        position: absolute;
        left: 4px;
        top: 25px;
        right: 0;
        color: #9d9c9c;

        .label{
          line-height: 19px;
        }

        select{
          position: absolute;
          right: 4px;
          top: 0;
          height: 19px;
          left: 40px;
          background-color: #181818;
          color: #888888;
          border: 1px solid #7b7b7b;
        }
      }
    }

    &.brush,
    &.grid{
      color: $menu-text-color;
      font-size: 12px;

      label{
        opacity: 0.7;
        line-height: 19px;
        padding: 0 4px;
        width: 110px;
      }

      .rangeselect{
        display: flex;
        margin-bottom: 3px;


        input[type="text"]{
          padding: 0 4px;
          width: 30px;
          height: 19px;
          margin-left: 4px;
          margin-right: 2px;
          font-size: 11px;
        }

      }

      .dither{
        display: flex;

      }

      .patterns{
        display: flex;
        width: 140%;;

        .pattern{
            width: 100%;
            height: 20px;
            margin-right: 2px;
            border: 1px solid black;
            background-color: #808080;
            background-size: contain;

            &.invert.hasPattern{
              filter: invert(1);
              border: 1px solid white;
              background-color: #A0A0A0;
              opacity: 0.5;

              &.active{
                border-color: #600fe0;
                box-shadow: 0 0 2px blue;
                opacity: 0.9;
              }

            }

            &.p1{
              background-image: url("../_img/patterns/dots.png");
            }

            &.p2{
              background-image: url("../_img/patterns/cross.png");
            }

            &.p3{
              background-image: url("../_img/patterns/grid.png");
            }

            &:last-of-type{
              margin-right: 0;
              background-color: transparent;
              background-image: url("../_img/caret.svg");
              opacity: 0.5;
              &:hover{
                background-color: transparent;
                opacity: 1;
              }
            }

            &:hover{
              cursor: pointer;
              background-color: white;

              &.invert.hasPattern{
                background-color: white;
                opacity: 1;
              }
            }


          &.active{
            border-color: $active-color;
            box-shadow: 0 0 2px yellow;
          }

        }
      }


    }

    &.color{
      .inner{
        overflow: hidden;
      }
      .colorpicker{
        height: 120px;
        width: 100%;
        overflow: hidden;

        canvas{
          border-right: 1px solid black;
        }
        canvas:last-of-type{
          border-left: 1px solid black;
          margin-left: 4px;
        }

        .dot{
          position: absolute;
          pointer-events: none;
          width: 10px;
          height: 10px;
          border: 2px solid white;
          border-radius: 50%;
          margin: -5px 0 0 -5px;
          left: 119px;
          top: 0;
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.7), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
        }

        .line{
          position: absolute;
          pointer-events: none;
          width: 0;
          height: 0;
          border-bottom: 5px solid transparent;
          border-top: 5px solid transparent;
          border-right: 5px solid white;
          left: 144px;
          filter: drop-shadow(0px 0px 2px black);
          top: 0;
          margin-top: -5px;

          &:before{
            content: "";
            position: absolute;
            left: -20px;
            top: -5px;
            width: 0;
            height: 0;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            border-left: 5px solid white;
          }
        }
      }
    }
  }

  .caption{
    background-color: $button-background-dark;
    color: $menu-text-color;
    padding: 3px 5px 3px 16px;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: unset;
    height: 21px;
    border: none;
    border-bottom: 1px solid black;
    justify-content: flex-start;
    user-select: none;

    i{
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      background-image: url("../_img/caret.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(90deg);
      transition: transform 0.2s ease-in-out;
      opacity: 0.7;
    }

    .close{
      position: absolute;
      height: 20px;
      width: 20px;
      line-height: 20px;
      right: 0;
      top: 0;
      text-align: center;
      cursor: pointer;
    }
  }

  .subpanel{
    color: $menu-text-color;
    padding: 4px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    position: relative;

    .label{
      opacity: 0.7;
      font-size: 12px;
      margin-bottom: 4px;
    }

    .value{
      position: absolute;
      right: 4px;
      top: 4px;
    }

    .button.square{
      position: absolute;
      right: 4px;
      top: 4px;
      width: 16px;
      height: 16px;
      border: 1px solid black;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      &:hover{
        cursor: pointer;
      }

      &.prev{
        right: 21px;
      }
    }

    select{
      color: $menu-text-color;
      width: 100%;
      border: 1px solid black;
      background-color: transparent;
      margin: 4px 0;

      &:focus{
        outline: none;
      }
    }
  }


  input[type=range] {
    width: 100%;
  }

}


body.withsidepanel{
  .sidepanel{
    display: block;
  }
}

body.override{
  .sidepanel{
    .panel{
      .layer.system{
        display: none;

        + .layer{
          background-color: rgba(255, 255, 255, 0.1);
          color: white;
        }
      }
    }
  }

  .splitpanel .panel .toolbar .toolpanel .options{
    .checkbox.mask{
      display: none;
    }

    .checkbox.pressure span{
      &:before{
        background-color: #c0c0c0;
        box-shadow: inset 0 0 0px 2px black;
      }

    }
  }
  
}