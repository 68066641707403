.checkbox{
  display: block;

  label{
    position: relative;

    span{
      padding: 2px 0 2px 18px;
      line-height: 14px;
      white-space: nowrap;
      color: $menu-text-color;

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 1px;
        width: 14px;
        height: 14px;
        background-color: #2b2b2b;
        border: 1px solid #8a8a8a;
      }
    }

    &:hover{
      cursor: pointer;

      span{
        color: #d7d7d7;

        &:before{
          border: 1px solid #e8e8e8;
        }
      }
    }
  }

  input{
    opacity: 0;
    position: absolute;

    &:checked + span{
      &:before{
        background-color: #c0c0c0;
        box-shadow: inset 0 0 0px 2px black;
      }
    }
  }
}

input[type="number"],
input[type="text"]{
  display: inline-block;
  background-color: #2b2b2b;
  border: 1px solid #6A6A6A;
  color: #BBBBBB;
  padding: 4px;

  &:focus{
    outline: none;
  }
}

.yesno{
  display: flex;
  width: 70px;
  height: 18px;
  border: 1px solid #6A6A6A;
  margin: 2px 2px 0 0;
  padding-top: 1px;
  font-size: 12px;
  position: relative;
  overflow: hidden;

  .option{
    width: 50px;
    text-align: center;
    opacity: 0.5;
    position: relative;
    z-index: 2;

    &:nth-child(2){
      opacity: 1;
      color: black;
    }
  }

  &:before{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 30px;
    background-color: $menu-text-color;
    transition: left 0.3s ease-in-out;
  }

  &:hover{
    cursor: pointer;

    .option{
      opacity: 1;

      &:nth-child(2){
        opacity: 1 !important;
      }
    }
  }

  &.selected{
    .option{
      &:nth-child(1){
        opacity: 1;
        color: black;
      }

      &:nth-child(2){
        opacity: 0.5;
        color: inherit;
      }
    }

    &:before{
      left: 0;
    }
  }
}